import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AppCards from "../../components/UI/AppCards";
import React, { useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { Navigate, useNavigate } from "react-router-dom";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  return (
    <div className="flex flex-col items-center min-h-[60vh] justify-center">
      <form
        className="w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(email);
          //  console.log(e);

          BookingApi.sentVerificationcode(email)
            .then((e) => {
              if (e.status == "success") {
                console.log(e.status);
                alert("Email Sent Sucessfully");
                navigate("/updatePassword");
              } else {
                alert("Email is not registered");
              }
              return e.data;
            })
            .catch((e) => {
              return e;
            });
        }}
      >
        <AppCards className="box-border p-4">
          <header className="flex flex-col gap-2 box-border py-12">
            <h2 className="w-full  text-2xl font-semibold  text-[#004188] ">
              Forgot your Password ?
            </h2>
            <p className="font-light">
              A link to reset your password will be sent to the registered Email
              address
            </p>
          </header>

          <Grid item xs={12} sm={6}>
            <AppInputSwitcher
              value={email}
              onchange={(v) => {
                setEmail(v);
              }}
              //className="h-24"
              type="text"
              variant="material"
              required
              label="Email Address"
              key="email"
              options={[]}
            />
          </Grid>
          <div className="w-full text-[#ffffff] ">
            <Button
              type="submit"
              variant="outlined"
              sx={{ mt: 3, mb: 2, bgcolor: "#020D53" }}
            >
              Submit
            </Button>
          </div>
        </AppCards>
      </form>
    </div>
  );
}
