import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect, useRef, useState } from "react";

import AppButton from "../../components/UI/AppButtons/AppButton";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import { AppSecondaryNavigation } from "../../@types/AppSecondaryNavigation";
import AppStandardButton from "../../components/UI/AppButtons";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection/";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingGetters from "../../store/onlineBooking/getters";
import { BreadCrumbs } from "../../components/UI/AppBreadCrumbs";
import { CustomerDetailFormField } from "./UserDetailsForm";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Modal } from "../../components/UI/AppModal";
import { ReservationDetailsPreview } from "../../components/Layouts/ReservationDetailsPreview/ReservationDetailsPreview";
import SecondaryNavConfig from "../../API/responses/SecondaryNavConfig.json";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import { AllPaymentMethodsResponse } from "../../@types/DTO/AllPaymentMethods";
import { PayPal } from "../../components/Layouts/PaymentForms/PayPal";
import { createSearchParams, useNavigate } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
//import { Nexi } from "../../components/Layouts/PaymentForms/Nexi";
import { t } from "i18next";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
// import { CompanyInvoice } from "../../components/Layouts/PaymentForms/CompanyInvoice";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
  WebCheckInFormFields,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { SelfDriverFormFields } from "../../components/Layouts/WebCheckInForms/FormKeys";
import { AppInputSwitchProps } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { object } from "prop-types";
import { BookingDetails } from "../../@types/Booking/bookingDetails";
import { companyDetails } from "../../@types/Booking/companyDetails";
import { LoadingButton } from "@mui/lab";
import { ReservationSuccessfullResponse } from "../../@types/DTO/ApiResponses";
import TagManager from "react-gtm-module";
//import { GTM } from "../../config/googleTagManager";
import { BookingConfirmPage } from "../bookingConfirmation";
//GTM;
function PaymentDetailsPage({
  values = {},
  initialValues = { ...DefaultValuesDetailsForm },
  onSubmit = () => {},
  onChange = () => {},
  children = <></>,
}: IndividualFormProps): JSX.Element {
  const [
    {
      setValue,
      getValues,

      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = useWebCheckInForm(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const tagManagerArgs = {
    gtmId: "GTM-N5FVP9S",
  };
  TagManager.initialize(tagManagerArgs);
  // GTM;
  const { t: translate } = useTranslation();
  const [companyinvoiceDetails, setCompanyinvoiceDetails] = useState<any>([]);
  const currentLoggedInuser: string | undefined =
    localStorage.getItem("userID") || undefined;
  // console.log(currentLoggedInuser);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const payPalFormRef = useRef<HTMLFormElement>();
  const [checked, setChecked] = React.useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    console.log(checked);
  };
  const nexiFormRef = useRef<HTMLFormElement>();
  const [allPaymentMethods, setAllPaymentMethods] =
    useState<AllPaymentMethodsResponse>({
      paypal: [],
      wireTransfer: undefined,
      nexi: [],
    });
  // useState<AllPaymentMethodsResponse>({
  //   nexi: [],
  // });
  const ReservationDetails = BookingGetters.getBookingDetails();

  const [ReservationId, setReservationId] = useState<number>(0);

  const { vehicleDetails, paymentDetails } =
    BookingGetters.getSelectedVehicleDetails();
  const UserDetails = BookingGetters.getUserDetails();

  //const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getPrivacyAndTerms().then((e) => {
      setShowDisclaimer(true);
      dispatch(BOOKING_ACTIONS.setRentalTerms(e.data.conditionText));
    });
  }, []);

  // const { t: translate } = useTranslation();
  const [userDetails, setUserDetails] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");

  // const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(BOOKING_ACTIONS.setUserDetails(userDetails));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userDetails]);

  const [formStates, formFields] = useWebCheckInForm(
    CustomerDetailFormField,
    userDetails,
    (e) => {
      setUserDetails({ ...userDetails, ...e });
      // console.log(userDetails);
    }
  );

  const [isShowingPreview, setIsShowingPreview] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!paymentDetails?.price) {
        navigateTo("/");
      }
    }, 1000);
    BookingApi.getAllPaymentMethods().then((e) => {
      setAllPaymentMethods(e);
      //  setAllPaymentMethods({ ...e, wireTransfer: "" || [] }); //- Compulsory adding wire transfer
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const searchParams: string = createSearchParams({
    dropOffDateAndTime: new Date(ReservationDetails.dropOffDateAndTime)
      .getTime()
      .toString(),
    pickUpDateAndTime: new Date(ReservationDetails.pickUpDateAndTime)
      .getTime()
      .toString(),
    pickUpLocation: ReservationDetails.pickUpLocationDetails.code,
    dropOffLocation: ReservationDetails.dropOffLocationDetails.code,
    reservationSource: ReservationDetails.reservationSource.code,
    macroGroupName: ReservationDetails.macroGroupName,
    discountValueWithoutVat: ReservationDetails.discountValueWithoutVat,
    agreementCoupon: ReservationDetails.agreementCoupon,
    showPics: "true",
    showOptionalImage: "true",
    showVehicleParameter: "true",
    showVehicleExtraImage: "true",
  }).toString();
  // console.log(searchParams);

  const options: Array<AppSecondaryNavigation> = [
    {
      to: "/",
      label: SecondaryNavConfig.search,
    },
    {
      to: "/choose/vehicle?" + searchParams,
      label: SecondaryNavConfig.choose,
    },
    {
      to: "#",
      label: SecondaryNavConfig.reserveAndPay,
      isActive: true,
    },
  ];

  return (
    <section className="flex flex-col ">
      <Modal
        isOpen={isShowingPreview}
        handleClose={() => {
          setIsShowingPreview(false);
        }}
      >
        <div className="h-[93%] overflow-y-hidden">
          <ReservationDetailsPreview />
        </div>
        <div className="flex justify-center items-center">
          <AppButton
            loading={isSaving}
            color="success"
            className="grow"
            onClick={() => {
              setIsSaving(true);
              BookingApi.createBooking(
                ReservationDetails,
                UserDetails,
                vehicleDetails as Vehicle,
                paymentDetails as SelectedVehicleDetails,
                selectedPaymentMethod,
                companyinvoiceDetails,
                currentLoggedInuser
              ).then((e) => {
                setReservationId(e.data.data.dbId);
                setIsSaving(false);
                switch (selectedPaymentMethod.toLowerCase()) {
                  case "": {
                    return;
                  }
                  case "paypal": {
                    payPalFormRef?.current?.submit();
                    return;
                  }
                  case "nexi": {
                    // nexiFormRef?.current?.submit();
                    BookingApi.getPaymentLink(
                      e.data.data.dbId,
                      paymentDetails?.price || 0
                    ).then((e) => {
                      console.log(e);
                      if (e.generateLink) {
                        window.open(e.generateLink, "_self");
                      }
                    });
                    return;
                  }
                  case "wiretransfer": {
                    BookingApi.sendEmailForWireTransfer(e.data.data.dbId);
                    navigateTo("/MovementRenewal/UrlDoneChargeBook");
                    return;
                    // return BookingConfirmPage();
                  }
                  default: {
                    BookingApi.getPaymentLink(
                      e.data.data.dbId,
                      paymentDetails?.price || 0
                    ).then((e) => {
                      console.log(e);
                      if (e.generateLink) {
                        window.open(e.generateLink, "_self");
                      }
                    });
                  }
                }
              });
            }}
          >
            <span className="text-center w-full h-full">{t("Confirm")}</span>
          </AppButton>
        </div>
      </Modal>

      <div className="bg-white w-full box-border px-8">
        <BreadCrumbs separator={<KeyboardArrowRight />} elements={options} />
      </div>
      {/* <p className="text-black">{JSON.stringify(userDetails)}</p> */}
      <div className="min-h-screen flex flex-row">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsShowingPreview(true);
          }}
          className="grow flex flex-col grow gap-16 box-border p-8 bg-gray-100"
        >
          <div className="bg-white box-border p-8">
            <h2 className="text-xl text-black pb-8 font-bold">
              {t("Personal Details")}
            </h2>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              {Object.values(formFields).map((el) => {
                return (
                  <AppInputSwitcher
                    {...el}
                    key={el.key}
                    label={translate(el.label || "")}
                    className={"h-20 sm:w-full box-border px-8"}
                    value={userDetails[el.key] || formStates.getValues(el.key)}
                    onchange={(e) => {
                      formStates.setValue(el.key, e.toString(), {
                        shouldValidate: true,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col bg-white box-border p-8   ">
            <div className="">
              <AppInputSwitcher
                label={t("Contract Info")}
                type="text"
                readOnly={true}
                variant="standard"
                className={"h-22 mt-2 mb-2"}
                onchange={() => {}}
                key="info"
                options={[]}
              />
            </div>
            <div className="flex flex-col">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={t("Invoice to company")}
              />
              {checked ? (
                <form
                  onSubmit={handleSubmit((e) => {
                    //console.log(e);

                    onSubmit(e);
                  })}
                  //   className={"flex flex-row flex-wrap"}
                >
                  <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {Object.values(individualFormFields).map((el, index) => {
                      const readOnly =
                        el.readOnly ||
                        (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                          !!initialValues[el.key]);
                      const options: RegisterOptions = {
                        required: {
                          value: !!el.required && !readOnly,
                          message: "This is a required field",
                        },
                      };
                      if (el.type === "email") {
                        options.pattern = {
                          value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                          message: "Please provide a valid email",
                        };
                      }
                      return (
                        <div key={index}>
                          <AppInputSwitcher
                            {...el}
                            key={index}
                            autocomplete={false}
                            required={false}
                            {...register(el.key, {
                              ...options,
                            })}
                            readOnly={readOnly}
                            value={
                              getValues(el.key) ||
                              initialValues[el.key] ||
                              values[el.key] ||
                              ""
                            }
                            label={translate(el.label || "")}
                            placeholder={translate(el.placeholder || "")}
                            className={"h-20 box-border px-8"}
                            // className={"h-[4.3rem]"}
                            onChange={() => {}}
                            onchange={(e) => {
                              const r: any = Object.keys(
                                CompanyInvoiceFormFields
                              ).at(index);
                              setCompanyinvoiceDetails({
                                ...companyinvoiceDetails,
                                [r]: e,
                              });
                              // console.log(companyinvoiceDetails);

                              setValue(el.key, e || "", {
                                shouldValidate: true,
                              });
                            }}
                          />
                          <FormFieldErrorMessage>
                            {errors[el.key]?.message}
                          </FormFieldErrorMessage>
                        </div>
                      );
                    })}
                  </div>
                  {children}
                </form>
              ) : (
                ""
              )}
            </div>
            <div>
              <AppInputSwitcher
                label={t("Info Privacy")}
                type="text"
                variant="standard"
                className={"h-22 mt-2 mb-2"}
                onchange={() => {}}
                key="privacy"
                options={[]}
                readOnly={true}
              />
            </div>
            <div className="flex flex-row flex-wrap gap-8">
              <AppStandardButton
                // clickHandler={() => {
                //   const link = document.createElement("a");
                //   link.download = `privacy policy.pdf`;
                //   link.href = "/assets/t&c/PrivacyPolicy_en.pdf";
                //   link.click();
                // }}
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.termsConditionFile;
                    fetch(url).then(async (e) => {
                      const fileBlob = await e.blob();
                      const linkObj = document.createElement("a");
                      linkObj.href = URL.createObjectURL(fileBlob);
                      linkObj.target = "_blank";
                      linkObj.click();
                      return fileBlob;
                    });
                  });
                }}
                variant="outlined"
                style={{ borderRadius: "0" }}
                className="h-12 w-80 mt-8"
              >
                {t("Download terms and conditions")}{" "}
              </AppStandardButton>
              <AppStandardButton
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.privacyfileUrl;

                    fetch(url).then(async (e) => {
                      const fileBlob = await e.blob();
                      // let file=new File([fileBlob],"test.pdf",{
                      //type: e.type
                      // });

                      const linkObj = document.createElement("a");
                      linkObj.href = URL.createObjectURL(fileBlob);
                      linkObj.target = "_blank";
                      linkObj.click();
                      return fileBlob;
                    });

                    // const link = document.createElement("a");
                    // link.href = e.data.termsConditionFile;
                    // // link.download = e.data.termsConditionFile;
                    // link.target = "_blank";
                    // link.click();
                  });
                }}
                variant="outlined"
                style={{ borderRadius: "0" }}
                className="h-12 w-80 mt-8"
              >
                {t("Download the privacy policy")}{" "}
              </AppStandardButton>
            </div>
            <AppStandardMultiSelectCheckBox
              required={true}
              header={t(
                "By clicking you're agreeing to our terms and conditions"
              )}
              options={[
                {
                  label: t("I have read and agree to the rental conditions"),
                  value: "I have read and agree to the rental conditions",
                },
              ]}
            />
          </div>
          <footer className="flex flex-row flex-wrap bg-white box-border p-8">
            <div className="flex flex-col grow">
              <AppRadioButton
                required={true}
                label={t("Mode of Payment")}
                value={selectedPaymentMethod}
                options={Object.keys(allPaymentMethods).map((el) =>
                  el.toUpperCase()
                )}
                onChange={(e) => {
                  setSelectedPaymentMethod(e);
                }}
              />
            </div>
            <div className="flex flex-row flex-wrap gap-6">
              <AppStandardButton
                color={"confirmBookingButton"}
                type="submit"
                className="h-12 w-44 flex items-center justify-center"
                style={{ borderRadius: "0" }}
              >
                <p className="w-full h-full flex items-center justify-center">
                  {translate("Confirm")}
                </p>
              </AppStandardButton>
            </div>
          </footer>
        </form>
      </div>
      <div className={"text-black hidden"}>
        {allPaymentMethods.paypal[0]?.developmentMode == true &&
          "paypal" in allPaymentMethods && (
            <PayPal
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={payPalFormRef}
              postUrl={allPaymentMethods.paypal[0]?.sandboxPaymentURL || ""}
              companyEmail={
                allPaymentMethods.paypal[0]?.sandboxEmailCompany || ""
              }
              amount={paymentDetails?.price || 0}
              returnLink={allPaymentMethods.paypal[0]?.sandboxReturnURL || ""}
              notify_url={allPaymentMethods.paypal[0]?.sandboxNotifyURL || ""}
              cancel_url={allPaymentMethods.paypal[0]?.sandboxCancelURL || ""}
              //Payment link for autoone
              // returnLink={
              //   "https://autoone.myrent.it/MyRentWeb/MovementRenewal/UrlDonePayPal"
              // }
              // notify_url={
              //   "https://autoone.myrent.it/MyRentWeb/MovementRenewal/UrlDonePayPal"
              // }
              // cancel_url={
              //   "https://autoone.myrent.it/MyRentWeb/MRRentalAgreement/UrlNotDoneExternal"
              // }
              itemName={"NAME HERE"}
              reservationId={ReservationId}
            />
          )}

        {allPaymentMethods.paypal[0]?.developmentMode == false &&
          "paypal" in allPaymentMethods && (
            <PayPal
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={payPalFormRef}
              postUrl={allPaymentMethods.paypal[0]?.productionPaymentURL || ""}
              companyEmail={
                allPaymentMethods.paypal[0]?.productionEmailCompany || ""
              }
              amount={paymentDetails?.price || 0}
              returnLink={
                allPaymentMethods.paypal[0]?.productionReturnURL || ""
              }
              notify_url={
                allPaymentMethods.paypal[0]?.productionNotifyURL || ""
              }
              cancel_url={
                allPaymentMethods.paypal[0]?.productionCancelURL || ""
              }
              //Payment link for autoone
              // returnLink={
              //   "https://autoone.myrent.it/MyRentWeb/MovementRenewal/UrlDonePayPal"
              // }
              // notify_url={
              //   "https://autoone.myrent.it/MyRentWeb/MovementRenewal/UrlDonePayPal"
              // }
              // cancel_url={
              //   "https://autoone.myrent.it/MyRentWeb/MRRentalAgreement/UrlNotDoneExternal"
              // }
              itemName={"NAME HERE"}
              reservationId={ReservationId}
            />
          )}
        {/* {allPaymentMethods && "nexi" in allPaymentMethods && (
          <Nexi
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={nexiFormRef}
            postUrl={allPaymentMethods.nexi[0]?.customAttribute1 || ""}
            // companyEmail={allPaymentMethods.nexi[0]?.fieldName || ""}
            amount={paymentDetails?.price || 0}
            returnLink={"https://www.google.com"}
            notify_url={"https://www.youtube.com"}
            cancel_url={"https://www.test.com"}
            itemName={"NAME HERE"}
          />
        )} */}
      </div>
    </section>
  );
}
export { PaymentDetailsPage };
