import {
  FooterConfiguration,
  HeaderConfiguration,
  HomePageConfiguration,
} from "../../@types/Pages/PageConfiguration";

import AppFooter from "./AppFooter/AppFooter";
import AppHeader from "./AppHeader/AppHeader";
import FooterResponse from "../../API/responses/Footer.json";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import { BookingGetters } from "../../store/onlineBooking/getters";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";

BasePageLayout.propTypes = {
  children: PropTypes.node,
  rootClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  headerClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  mainClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  footerClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
function formatUrl(url: string): string {
  return url;
}

export function BasePageLayout(props) {
  const homePageConfig = BookingGetters.getAppSettings();
  const headerConfig: HeaderConfiguration =
    PageConfigGetters.getAppHeaderConfig();
  const FooterConfig: FooterConfiguration =
    PageConfigGetters.getAppFooterConfig();

  const route = useLocation();
  return (
    <div
      className={`min-h-screen flex flex-col bg-cover  ${
        props.rootClasses || ""
      }`}
    >
      <div className={`text-white`}>
        <header className={props.headerClasses}>
          {!headerConfig.isHidden && <AppHeader></AppHeader>}
        </header>
        <main
          className={`grow bg-cover ${props.mainClasses}`}
          // style={{
          //   backgroundImage:
          //     route.pathname == "/"
          //       ? `url(${
          //           homePageConfig?.config?.backgroundImageUrl ||
          //           " https://dogmasystemscdn.s3.amazonaws.com/autoone/image/logo1.jpg"
          //         })`
          //       : "",
          // }}
        >
          {props.children}
        </main>
      </div>
      <a href="#" id="whatsapp">
        <img src="assets/img/whatsapp.png" />
      </a>
      <div className="menu-list">
        <div id="close">Chiudi</div>
        <ul>
          <li className="menu-links" id="about">
            Chi siamo
          </li>
          <li className="menu-links" id="photos">
            Le nostre sedi
          </li>
          <li className="menu-links" id="terms">
            Assistenza
          </li>
          <li className="menu-links" id="contact">
            Contatti
          </li>
        </ul>
      </div>
      {/**FOOTER APPLICAZIONE */}
      {/* {!FooterConfig.isHidden && (
        <AppFooter {...FooterResponse} className={props.footerClasses} />
      )} */}
    </div>
  );
}
