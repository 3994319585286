import React from "react";
import { useNavigate } from "react-router-dom";

function AutoCard(props): JSX.Element {
  const navigateTo = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    // Codice da eseguire quando si verifica l'evento di click
    //navigateTo("booking/pay");
  };

  return (
    <div className="offerta">
      <div className="left">
        <span className="gruppo">
          {props.data?.Vehicle?.groupPic?.description}
        </span>
        <h3>Q2</h3>
        <span className="vettSimile">
          o vettura simile dello stesso gruppo<i className="ttSimile"></i>
        </span>
      </div>
      <div className="right">
        <span>20</span>€ / giorno
      </div>
      <img src={props.data?.Vehicle?.vehicleGroupPic} />
      {props.data?.Vehicle?.vehicleParameter &&
        props.data?.Vehicle?.vehicleParameter.map((el, index) => {
          console.log(el["name :"]);
          return (
            <div className="opt" key={`opt_${index}`}>
              <i className="trazione"></i>
              {el["name :"]}
            </div>
          );
        })}
      {/* <div className="opt">
        <i className="alimentazione"></i>Benzina
      </div>
      <div className="opt">
        <i className="motore"> </i>3 Porte
      </div>
      <div className="opt">
        <i className="cambio"></i>Manuale
      </div>
      <div className="opt">
        <i className="trazione"></i>1 Bagaglio
      </div> */}
      <div className="azione">
        <a onClick={handleClick}>Prenota</a>
      </div>
    </div>
  );
}
export default AutoCard;
