import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { AppStandardButton as AppButton } from "../../UI/AppButtons";
import { InsuranceCardProps } from "./Insurance.props";
import React from "react";
import { t } from "i18next";

// InsuranceCardsLayout.propTypes = {
//   ...InsuranceCardsLayoutProps,
// };
// InsuranceCardsLayout.defaultProps = {
//   onSelected: () => {},
// };

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
}: InsuranceCardProps) {
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return { ...el, availableInsurances };
  });
  const secondaryTextColor = " text-blue-600 ";
  const border = " border-[0.5px] border-solid border-slate-600 ";
  return (
    <table className="table-auto  border-collapse text-black w-full">
      <thead>
        <tr className={border}>
          <th className={border + "w-36"}>
            <h3>Tabella dei prezzi</h3>
            {/* <p className="text-grey-200 font-light">Lorem Ipsum</p> */}
          </th>

          {allInsurances.map((el, index) => (
            <th className={border + "w-36 h-20 relative"} key={index}>
              {el.isRecommended && (
                <div className="text-grey-200 font-light">
                  <img
                    src="/assets/images/Favo-icon@2x.png"
                    className="h-12 w-12 top-0 absolute"
                  />
                </div>
              )}
              <h3>{el.name}</h3>
              <p className={secondaryTextColor + "text-xl font-normal"}>
                {formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                  .symbol + " "}
                {el.pricePerDayInUSD}
                <span className="text-sm">/day</span>
              </p>
              {/*<p>{el.description}</p>*/}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="overflow-scroll w-full">
        {Object.keys(allInsuranceFeatures).map((el, index) => {
          return (
            <tr key={index} className={border + "font-bold capitalize"}>
              <td className={border + "h-20 box-border px-2"}>{el}</td>
              {allInsurances.map((insurance, i) => {
                if (insurance.availableInsurances[el]) {
                  return (
                    <td className={border + "min-w-44"} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        <DoneTwoTone className="text-green-800 font-bold" />
                      </p>
                    </td>
                  );
                }
                return (
                  <td className={border} key={i}>
                    <p className="h-full w-full flex justify-center items-center">
                      <CloseTwoTone className="text-red-800" />
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot className="">
        <tr>
          <td className={border + "w-36 h-20"}>
            <AppButton
              clickHandler={() => {
                onSelected(-1);
              }}
              color="red"
              className="box-border px-6 text-white"
            >
              {/* Cancel */}
              {t("Cancel")}
            </AppButton>
          </td>
          {allInsurances.map((el, index) => (
            <td className={border + " w-36 h-20 relative"} key={index}>
              <AppButton
                color={selectedInsuranceIndex == index ? "success" : "primary"}
                clickHandler={() => {
                  onSelected(index);
                }}
                className="box-border px-6"
              >
                {/* Choose */}
                {t("Choose")}
              </AppButton>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
export { InsuranceCardsLayout };
