import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { AppStandardBreadCrumbsProps } from "./BreadCrumbs.props";
import { useBreadCrumbStyles } from "./BreadCrumbs.styles";
import React from "react";
StandardBreadCrumbs.defaultProps = {
  separator: "/",
  elements: [],
};
function StandardBreadCrumbs({
  separator,
  elements,
}: AppStandardBreadCrumbsProps) {
  const StyleClasses = useBreadCrumbStyles();
  return (
    <Breadcrumbs separator={separator} className={StyleClasses.default}>
      {elements.map((el, index) => {
        if (!el.isDisabled) {
          return (
            <Link
              to={el.to}
              key={index}
              className={el.isActive ? "font-bold" : ""}
            >
              {el.label}
            </Link>
          );
        } else {
          return (
            <span className="text-gray-600 cursor-default" key={index}>
              {el.label}
            </span>
          );
        }
      })}
    </Breadcrumbs>
  );
}
export { StandardBreadCrumbs };
