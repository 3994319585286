import {
  AccountCircleOutlined,
  AddBox,
  CalendarMonthOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LoginIcon from "@mui/icons-material/Login";
import React, { useEffect, useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import CarRentalIcon from "@mui/icons-material/CarRental";
import { AppFlagSelect } from "../../UI/AppSelectOptions/AppSelect";
import { AppStandardButton } from "../../UI/AppButtons";
import BookingGetters from "../../../store/onlineBooking/getters";
import { HeaderConfiguration } from "../../../@types/Pages/PageConfiguration";
import { LoggedInUserMenu } from "../../UI/AppMenu";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import PropTypes from "prop-types";
import { TimeInMinutes } from "../../../helpers/timerConfig";
import { differenceInSeconds } from "date-fns";
import { useTimer } from "react-timer-hook/dist/index";
import { useTranslation } from "react-i18next";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import AppButton from "../../UI/AppButtons/AppButton";
import BookingApi from "../../../API/Apis/BookingApi";

import en from "../../../assets/img/en.png";
import it from "../../../assets/img/it.png";
import logo from "../../../assets/img/logo.png";
import utente from "../../../assets/img/user.png";

const MenuOptions = [
  {
    // label: "New Reservation"
    label: "Nuova prenotazione",
    iconUrl: <AddBox />,
    clickHandler: () => {},
    href: "/",
  },
  {
    // label: "My personal Informations",
    label: "I miei dati personali",
    iconUrl: <AccountCircleOutlined />,
    clickHandler: () => {},
    href: "/accounts",
  },
  {
    // label: "My Reservations",
    label: "Le mie prenotazioni",
    iconUrl: <CalendarMonthOutlined />,
    clickHandler: () => {},
    href: "/my-reservations",
  },
  {
    label: "Logout",
    iconUrl: <LogoutOutlined />,
    clickHandler: () => {},
  },
];
const MenuHeader = {
  label: "Admin",
  avatar: (
    <img
      alt="user profile picture"
      src="https://ui-avatars.com/api/?name=Admin&color=7F9CF5&background=EBF4FF"
      className="h-12 w-12 m-0 p-0 border-2 border-solid border-grey-800 rounded-full box-border"
    />
  ),
  avatarClickHandler: () => {},
  labelClickHandler: () => {},
};

AppHeader.propTypes = {
  className: PropTypes.string,
};
AppHeader.defaultProps = {
  className: "",
};

export default function AppHeader({ className }): JSX.Element {
  const { i18n } = useTranslation();
  const navigateTo = useNavigate();
  const stateHeaderConfig: HeaderConfiguration =
    PageConfigGetters.getAppHeaderConfig();
  const expiryTime = BookingGetters.getSessionExpiryTime();
  const isLogged = localStorage.getItem("isUserLoggedin");
  const location = useLocation();
  const { minutes, seconds, pause } = useTimer({
    expiryTimestamp: new Date(expiryTime || Date.now() + 99999999999),
    onExpire() {
      //   console.log("exp");
      //console.log(expiryTime);
    },
  });

  useEffect(() => {
    const d = Math.ceil(
      differenceInSeconds(new Date(expiryTime), new Date().getTime()) / 60
    );
    if (location.pathname != "/") {
      if (d == 0) {
        alert("expired");
        navigateTo("/");
      }
    }
  });

  function onLogout() {
    if (isLogged == "true") {
      console.log("user is logged out ");
      localStorage.setItem("isUserLoggedin", "false");
      localStorage.removeItem("userID");
      localStorage.removeItem("customerId");
      alert("Log out Success");
      navigateTo("/signin");
    } else {
      alert("you are already logged out");
    }
  }

  const [showWebCheckIn, setShowWebCheckIn] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowWebCheckIn(e.webCheckIn);
    });
  }, [showWebCheckIn]);

  const appSettings = BookingGetters.getAppSettings();
  const [popperAnchorEl, setPopperAnchorEl] = useState<Element | null>(null);
  const [showMenu, setShowMenu] = useState(true);
  const [selected, changeSelected] = useState(-1);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  function handlePopperClick() {}

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowMenu(false);
    setPopperAnchorEl(null);
  };

  const openProfile = () => {
    navigateTo("/profile");
  };

  const openBooking = () => {
    navigateTo("/my-reservations");
  };

  const customerOpen = () => {
    navigateTo("/customerDetailsList");
  };
  const openNewBooking = () => {
    navigateTo("/");
  };

  const registerUser = () => {
    navigateTo("/signup");
  };

  const onLogin = () => {
    navigateTo("/signin");
  };
  const isUserLoggedin = localStorage.getItem("isUserLoggedin");
  const userType = localStorage.getItem("onlineUserType");
  const [showReserve, setShowReserve] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
      // setShowReserve(true);
    });
  }, [showReserve]);

  return (
    <header className="clearfix">
      <div className="header-container" id="headerDesktop">
        <h1 className="title">
          <img src={logo} style={{ height: "50px" }} />
        </h1>
        <nav>
          <ul>
            <li>
              <a href="#">Chi siamo</a>
            </li>
            <li>
              <a href="#">Le nostre sedi</a>
            </li>
            <li>
              <a href="#">Assistenza</a>
            </li>
            <li>
              <a href="#">Contattaci</a>
            </li>
            <li>
              <a href="#" onClick={onLogin}>
                <img src={utente} style={{ height: "30px" }} />
                &nbsp;Accedi
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div id="lingue">
        <a href="#">
          <img src={en} />
        </a>
        <a href="#">
          <img src={it} />
        </a>
      </div>
      <div id="headerMobile">
        <a href="#">
          <img src={logo} id="logoH" />
        </a>
        <div className="hamburger js-hover">
          <div className="hamburger__line hamburger__line--01">
            <div className="hamburger__line-in hamburger__line-in--01"></div>
          </div>
          <div className="hamburger__line hamburger__line--02">
            <div className="hamburger__line-in hamburger__line-in--02"></div>
          </div>
          <div className="hamburger__line hamburger__line--03">
            <div className="hamburger__line-in hamburger__line-in--03"></div>
          </div>
          <div className="hamburger__line hamburger__line--cross01">
            <div className="hamburger__line-in hamburger__line-in--cross01"></div>
          </div>
          <div className="hamburger__line hamburger__line--cross02">
            <div className="hamburger__line-in hamburger__line-in--cross02"></div>
          </div>
        </div>
      </div>
    </header>
  );

  {
    /* return (
    <header className={"flex flex-col "}>
      <nav className={`h-36 box-border py-4 flex flex-row ${className}`}>
        <div className="md:w-1/4 w-3/5 h-full box-border ">
          <Link to="/" style={{ outline: "unset" }}>
            {stateHeaderConfig.logoUrl && (
              <img
                src={stateHeaderConfig.logoUrl}
                //   src={appSettings?.imagePath || ""}
                alt="App Logo Here"
                className="w-full h-full box-border px-8 object-contain"
              />
            )}
          </Link>
        </div>
        <aside className=" w-full md:flex flex-row justify-end hidden items-center border-box p-4 gap-4 font-bold">
          {expiryTime > 0 && (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                size={50}
                variant="determinate"
                value={
                  (100 *
                    differenceInSeconds(
                      new Date(expiryTime),
                      new Date().getTime()
                    )) /
                  (TimeInMinutes * 60)
                }
                color="bookingButtonColor"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                  fontSize={"0.8rem"}
                >
                  {Math.ceil(
                    differenceInSeconds(
                      new Date(expiryTime),
                      new Date().getTime()
                    ) / 60
                  )}{" "}
                  min
                </Typography>
              </Box>
            </Box>
          )}
          {showWebCheckIn ? (
            <div>
              <Link to={"/booking/web-check-in"}>
                <AppStandardButton
                  variant={"contained"}
                  title={"web check in"}
                  color={"bookingButtonColor"}
                  disableElevation
                >
                  <p className="flex gap-2 flex-wrap">
                    <LoginOutlined />
                    <span> Web Check-in</span>
                  </p>
                </AppStandardButton>
              </Link>
            </div>
          ) : (
            ""
          )}

          <AppFlagSelect
            onChange={(e: string) => {
              if (e.toUpperCase().includes("US")) {
                e = "en";
              }
              i18n.changeLanguage(e).then(() => {
                setCurrentLanguage(e);
              });
            }}
            selectStyles={{ borderRadius: "3rem" }}
            className="h-8 border-box w-16"
            value={
              currentLanguage?.includes("en")
                ? "us"
                : currentLanguage.toLowerCase()
            }
            options={["us", "it"]}
          />
          {showReserve ? (
            <div>
              {isUserLoggedin == "true" ? (
                <div>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <div className="space-y-2">
                      <div className="w-8 h-0.5 bg-black"></div>
                      <div className="w-8 h-0.5 bg-black"></div>
                      <div className="w-8 h-0.5 bg-black"></div>
                    </div>
                  </Button>
                  <div className="space-x-4 text-black-500">
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className="hover:bg-[#020D53] hover:text-[#fff]">
                        <MenuItem onClick={openNewBooking}>
                          {" "}
                          <DashboardIcon className="px-1  mr-1 " />
                          DashBoard{" "}
                        </MenuItem>
                      </div>
                      <div className="hover:bg-[#020D53] hover:text-[#fff]">
                        <MenuItem onClick={openBooking}>
                          {" "}
                          <CarRentalIcon className="px-1  mr-1 " /> My Booking{" "}
                        </MenuItem>
                      </div>
                      <div className="hover:bg-[#020D53] hover:text-[#fff]">
                        <MenuItem onClick={openProfile}>
                          {" "}
                          <AccountCircleIcon className="px-1 mr-1" /> My Profile
                        </MenuItem>
                      </div>
                      {userType == "EndUser" ? (
                        <div className="hover:bg-[#020D53] hover:text-[#fff]">
                          <MenuItem onClick={customerOpen}>
                            {" "}
                            <GroupAddIcon className="px-1  mr-1" />
                            Driver Details
                          </MenuItem>
                        </div>
                      ) : (
                        // oh=nhiver pr text color white and background color blue
                        <div className="hover:bg-[#020D53] hover:text-[#fff]">
                          <MenuItem
                            className="!hover:bg-[#020D53] text-[#020D53]"
                            onClick={customerOpen}
                          >
                            {" "}
                            <GroupAddIcon className="px-1  mr-1" />
                            Customer Details
                          </MenuItem>
                        </div>
                      )}
                      <div className="hover:bg-[#020D53] hover:text-[#fff]">
                        <MenuItem onClick={onLogout}>
                          <LogoutIcon className="px-1  mr-1" />
                          Logout
                        </MenuItem>
                      </div>
                    </Menu>
                  </div>
                </div>
              ) : (
                <AppButton
                  onClick={onLogin}
                  color="bookingButtonColor"
                  className="h-8 text-black-500"
                >
                  Login
                </AppButton>
              )}
            </div>
          ) : (
            ""
          )}

          {BookingGetters.getIsLoggedIn() && MenuOptions && (
            <LoggedInUserMenu
              selectedIndex={selected}
              anchorElement={popperAnchorEl}
              clickHandler={handlePopperClick}
              isOpen={showMenu}
              handleClose={handleClose}
              changeOptionSelected={(e) => {
                changeSelected(e);
              }}
              options={MenuOptions || []}
              menuHeader={MenuHeader}
            />
          )}
        </aside>
      </nav>
    </header>
  ); */
  }
}
