import React, { useEffect, useState } from "react";
import { addDays, getDay } from "date-fns";

import AppButton from "../../UI/AppButtons";
import AppCard from "../../UI/AppCards";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { AppStandardMultiSelectCheckBox } from "../../UI/AppMultipleSelection/AppMultiSelection";
import BookingApi from "../../../API/Apis/BookingApi";
import { BookingDetails } from "../../../@types/Booking/bookingDetails";
import { BookingFormProps } from "./BookingForm.props";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Location } from "../../../@types/DTO/Locations";
import SearchIcon from "@mui/icons-material/Search";
import { setTime } from "../../../helpers/dateManipulators";
import { useTranslation } from "react-i18next";
import { BookingSourceResponse } from "../../../@types/DTO/BookingSource";
import { t } from "i18next";
import { MacroClassGroup } from "../../../@types/DTO/MacroClass";
import { useSearchParams } from "react-router-dom";

function getEnabledTimeSlots(
  locationArray: Location[],
  locationName: string,
  date: Date | null
) {
  return locationName && date
    ? locationArray
        .filter(
          (e) => e.locationName.toLowerCase() === locationName.toLowerCase()
        )[0]
        ?.openings.filter((e) => {
          // console.log(e.dayOfTheWeek, e.dayOfTheWeek % 7, getDay(date));
          return e.dayOfTheWeek % 7 == getDay(date);
        })
        .map((e) => {
          return {
            start: setTime(e.startTime, date),
            end: setTime(e.endTime, date),
          };
        })
    : [];
}
function BookingForm({
  onSubmit = () => {},
  className = "",
}: BookingFormProps): JSX.Element {
  const { t: translate } = useTranslation();
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();
  const isEmbedded = BookingGetters.getIsEmbedded();
  const AppSettings = BookingGetters.getAppSettings();
  const maxRentalAllowedDay = AppSettings?.maxRentalDurationInDays || 30;
  const [minRentalAllowedDay] = useState<number>(AppSettings?.minRentDays || 1);
  const [reservationSource, setReservationSource] = useState<string>(
    reduxBookingDetails.reservationSource.name
      ? reduxBookingDetails.pickUpLocationDetails.name
      : ""
  );
  const [macroGroupOptions, setMacroGroupOptions] = useState<MacroClassGroup[]>(
    []
  );
  const [selectedMacroGroup, setSelectedMacroGroup] = useState<string>("");
  const [reservationDestination, setReservationDestination] = useState<string>(
    reduxBookingDetails.dropOffLocationDetails.name
      ? reduxBookingDetails.dropOffLocationDetails.name
      : ""
  );
  const [reservationStartDate, setStartDate] = useState<Date | null>(
    reduxBookingDetails.pickUpDateAndTime
      ? new Date(reduxBookingDetails.pickUpDateAndTime)
      : null
  );
  const [reservationEndDate, setEndDate] = useState<Date | null>(
    reduxBookingDetails.dropOffDateAndTime
      ? new Date(reduxBookingDetails.dropOffDateAndTime)
      : null
  );
  const [reservationPlatform, setReservationPlatform] = useState<string>(
    reduxBookingDetails.reservationSource
      ? reduxBookingDetails.reservationSource.name
      : ""
  );
  const [isDroppingAtSameLocation, setIsDroppingAtSameLocation] =
    useState<string>(translate("drop at same location"));

  const [reservationSourceOptions, setReservationSourceOptions] = useState<
    BookingSourceResponse[]
  >([]);

  const [isAgeLimitSelected, setIsAgeLimitSelected] = useState<string>("");
  const [showCoupan, setshowCoupan] = useState<boolean>();

  const [agreementCoupon, setagreementCoupon] = useState<string>(
    reduxBookingDetails.agreementCoupon
      ? reduxBookingDetails.agreementCoupon
      : ""
  );
  const [discountValueWithoutVat, setDiscountValueWithoutVat] = useState<any>(
    reduxBookingDetails.discountValueWithoutVat
      ? reduxBookingDetails.discountValueWithoutVat
      : "0"
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [locationDetail, setLocationDetail] = useState<Location[]>([]);
  useEffect(() => {
    BookingApi.getReservationLocations().then((data: Record<string, any>) => {
      setLocationDetail(data.data || []);
    });
    BookingApi.getAllMacroGroups().then((e) => {
      setMacroGroupOptions(e);
    });
    BookingApi.getAppSetting()
      .then((e) => {
        setshowCoupan(e.showWebCodeInput);
        // setshowCoupan(false);
      })
      .catch((err) => {});
    BookingApi.getAllReservationSources().then((data) => {
      setReservationSourceOptions(data);
    });
  }, []);

  // {
  //   disableWeekends &&
  //     function disableWeekends(date) {
  //       return date.getDay() === 0 || date.getDay() === 6;
  //     };
  // }

  return (
    <main className={`${className}`}>
      <form
        className={"formBooking"}
        onSubmit={(e) => {
          e.preventDefault();
          // if (isEmbedded) {
          //   return window.open(
          //     location.origin + "/embed/choose/vehicle",
          //     "_self"
          //   );
          // }
          // console.log(
          //   reservationSourceOptions.find(
          //     (e) => e["Ragione Sociale"] === reservationPlatform
          //   ),
          //   reservationPlatform
          // );
          const bookingDetailsForLinkDirection: BookingDetails = {
            dropOffDateAndTime: reservationEndDate?.getTime() || Date.now(),
            dropOffLocationDetails: {
              name: reservationSource,
              code: locationDetail.filter(
                (e) => e.locationName === reservationDestination
              )[0].locationCode,
            },
            pickUpLocationDetails: {
              name: reservationDestination,
              code: locationDetail.filter(
                (e) => e.locationName === reservationSource
              )[0].locationCode,
            },
            pickUpDateAndTime: reservationStartDate?.getTime() || Date.now(),
            reservationSource: {
              name: reservationPlatform,
              code:
                reservationSourceOptions.find(
                  (e) => e["Ragione Sociale"] === reservationPlatform
                )?.Codice || "",
            },
            agreementCoupon: agreementCoupon,
            discountValueWithoutVat: discountValueWithoutVat,
            macroGroupName: selectedMacroGroup,
            showPics: true,
            showOptionalImage: true,
            showVehicleParameter: true,
            showVehicleExtraImage: true,
          };
          // setSearchParams(JSON.stringify(bookingDetailsForLinkDirection));
          onSubmit(bookingDetailsForLinkDirection);
        }}
      >
        <AppCard className="appBookingsy">
          <div className="contBooking">
            <div className="">
              <AppInputSwitcher
                required={true}
                className={"field left"}
                value={reservationSource}
                label={translate("pick up location")}
                key={"pickUpLocation"}
                options={locationDetail?.map((e: Location) => e.locationName)}
                type="select"
                variant="with-search"
                onchange={(e) => {
                  if (isDroppingAtSameLocation.length) {
                    setReservationDestination(e);
                  }
                  setReservationSource(e);
                  //  setMacroGroupSelection(e);
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              {!isDroppingAtSameLocation.length && (
                <AppInputSwitcher
                  required={true}
                  value={reservationDestination}
                  label={translate("drop off location")}
                  key={"dropOffLocation"}
                  options={locationDetail.map((e) => e.locationName)}
                  type="select"
                  variant="with-search"
                  onchange={(e) => {
                    setReservationDestination(e);
                    setEndDate(null);
                  }}
                />
              )}
            </div>
            <AppInputSwitcher
              disableOnDay={
                reservationSource && locationDetail.length
                  ? Object.keys(
                      locationDetail
                        .filter(
                          (e) =>
                            e.locationName.toLowerCase() ===
                            reservationSource.toLowerCase()
                        )[0]
                        ?.openings?.map((e) => {
                          return e.dayOfTheWeek % 7;
                        })
                        // .filter((e) => {
                        //   return (
                        //     (AppSettings?.noSabatoDomenica && e === 1) ||
                        //     e === 2 ||
                        //     e === 3 ||
                        //     e === 4 ||
                        //     e === 5
                        //   );
                        // })
                        .reduce(
                          (e, v) => {
                            delete e[v];
                            return e;
                          },
                          {
                            0: true,
                            1: true,
                            2: true,
                            3: true,
                            4: true,
                            5: true,
                            6: true,
                          }
                        )
                    ).map((e) => parseInt(e))
                  : []
              }
              required={true}
              className="field left"
              label={translate("reservation dates")}
              key={"reservationDates"}
              options={[]}
              startDate={reservationStartDate}
              endDate={reservationEndDate}
              // startDate={[reservationStartDate, addDays(new Date(), 30)]}
              // startDate={
              //   reservationStartDate && addDays(new Date(), minRentalAllowedDay)
              // }
              // endDate={
              //   reservationEndDate && addDays(new Date(), maxRentalAllowedDay)
              // }
              minDate={addDays(new Date(), minRentalAllowedDay)}
              //maxDate={addDays(new Date(), maxRentalAllowedDay)}
              maxDate={
                reservationStartDate
                  ? addDays(
                      new Date(reservationStartDate?.toString()),
                      maxRentalAllowedDay
                    )
                  : addDays(new Date(), 365)
              }
              type="date"
              variant="range"
              onchange={(selectedDateRange) => {
                if (selectedDateRange[0]) {
                  setStartDate(
                    setTime(
                      locationDetail
                        .filter((e) => e.locationName == reservationSource)[0]
                        ?.openings.filter(
                          (e) =>
                            getDay(selectedDateRange[0]) === e.dayOfTheWeek % 7
                        )[0].startTime || "",
                      selectedDateRange[0]
                    )
                  );
                } else {
                  setStartDate(null);
                }
                if (selectedDateRange[1]) {
                  setEndDate(
                    setTime(
                      locationDetail
                        .filter(
                          (e) => e.locationName == reservationDestination
                        )[0]
                        ?.openings.filter(
                          (e) =>
                            getDay(selectedDateRange[1]) === e.dayOfTheWeek % 7
                        )[0].startTime || "",
                      selectedDateRange[1]
                    )
                  );
                } else {
                  setEndDate(null);
                }
              }}
            />{" "}
            {/*{JSON.stringify(*/}
            {/*  getEnabledTimeSlots(*/}
            {/*    locationDetail,*/}
            {/*    reservationDestination,*/}
            {/*    reservationStartDate*/}
            {/*  )*/}
            {/*)}*/}
            <AppInputSwitcher
              required={true}
              disabled={!reservationStartDate}
              className="field left"
              label={translate("pick up time")}
              key={"pickUpTime"}
              enableTimesInRange={getEnabledTimeSlots(
                locationDetail,
                reservationSource,
                reservationStartDate
              )}
              value={reservationStartDate}
              options={[]}
              type="time"
              variant="slot"
              onchange={(e) => {
                setStartDate(e);
              }}
            />
            {/*{JSON.stringify(*/}
            {/*  getEnabledTimeSlots(*/}
            {/*    locationDetail,*/}
            {/*    reservationDestination,*/}
            {/*    reservationEndDate*/}
            {/*  )*/}
            {/*)}*/}
            <AppInputSwitcher
              enableTimesInRange={getEnabledTimeSlots(
                locationDetail,
                reservationDestination,
                reservationEndDate
              )}
              disabled={!reservationEndDate}
              required={true}
              className="min-w-[10rem] field left"
              label={translate("drop off time")}
              key={"dropOffTime"}
              options={[]}
              value={reservationEndDate}
              type="time"
              variant="slot"
              onchange={(e) => {
                setEndDate(e);
              }}
            />
            {AppSettings?.showMacroGroupSelection && (
              <AppInputSwitcher
                required={false}
                className={"w-full min-w-[12rem]"}
                type="select"
                variant="with-search"
                label={translate("Macro Group")}
                options={macroGroupOptions?.map((e) => e.label) || []}
                value={selectedMacroGroup}
                onchange={(e) => {
                  setSelectedMacroGroup(e);
                }}
              />
            )}
            {AppSettings?.showReservationGroupSelection && (
              <AppInputSwitcher
                required={true}
                className="w-full min-w-[12rem]"
                label={translate("reservation source")}
                key={"reservationPlatform"}
                options={reservationSourceOptions?.map(
                  (e) => e["Ragione Sociale"]
                )}
                value={reservationPlatform}
                type="select"
                variant="with-search"
                onchange={(e) => {
                  setReservationPlatform(e);
                }}
              />
            )}
            {/* <div className="object-none object-right-bottom  h-10 w-10 "> */}
            {showCoupan ? (
              <AppInputSwitcher
                required={false}
                className="min-w-[10rem] field left"
                label={translate("Voucher Code")}
                key={"Voucher Code"}
                placeholder={translate("Voucher Code")}
                options={[]}
                value={agreementCoupon}
                type="text"
                variant="slot"
                onchange={(e) => {
                  setagreementCoupon(e);
                  // console.log(e);
                }}
              />
            ) : (
              ""
            )}
            {/* </div> */}
            <div className="flex flex-row gap-2 w-full flex-wrap">
              {AppSettings?.locationReturnEnable && (
                <div className="min-w-fit max-w-fit">
                  <AppStandardMultiSelectCheckBox
                    value={[isDroppingAtSameLocation]}
                    onChange={(e) => {
                      setIsDroppingAtSameLocation(e[0] || "");
                      if (e.length) {
                        setReservationDestination(reservationSource);
                      }
                    }}
                    options={[translate("drop at same location")]}
                  />
                </div>
              )}

              <div className="fileta">
                <AppRadioButton
                  id="labeleta"
                  required
                  value={isAgeLimitSelected}
                  onChange={(e) => {
                    setIsAgeLimitSelected(e);
                  }}
                  options={[translate("age between 19-80")]}
                />
              </div>
            </div>
          </div>
          <AppButton
            color={"bookingButtonColor"}
            type={"submit"}
            endIcon={<SearchIcon />}
            className="h-14 w-full pulsCerca"
          >
            <p className={`w-full text-center`}>{translate("Search")}</p>
          </AppButton>
        </AppCard>
      </form>
    </main>
  );
}
export default BookingForm;
