import { string } from "prop-types";
//import { companyDetails } from "./../../@types/Booking/companyDetails.d";
import {
  PrivacyTerms,
  SsnByItalianCustomerDetails,
} from "./../../@types/DTO/ApiResponses";
import { SelectedVehicleDetails } from "./../../@types/Booking/SelectedVehicleDetails.d";
import { Vehicle } from "./../../@types/DTO/Vehicles.d";
import { CreateNewBooking, UserLoginResponse } from "../../@types/DTO/Booking";
import {
  CustomerDetailsResponse,
  CustomerDetailsResponse_2,
  DocumentUploadResponse,
  InsuranceDetails,
  ItalianCustomerDetailsUsingSSNResponse,
} from "../../@types/DTO/ApiResponses";
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { BookingDetails } from "./../../@types/Booking/bookingDetails.d";
import { DetailsFormValues } from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import { Location } from "../../@types/DTO/Locations";
import { PrivacyPolicy } from "../../@types/DTO/privacyPolicy";
import { StateResponse } from "../../@types/DTO/LocationRequests";
import { BookingSourceResponse } from "../../@types/DTO/BookingSource";
import {
  AllPaymentMethodsResponse,
  PaymentLinkResponse,
} from "../../@types/DTO/AllPaymentMethods";
import { ReservationSuccessfullResponse } from "../../@types/DTO/ReservationDetails";
import { AppSettingResponse } from "../../@types/DTO/AppSetting";
import { companyDetails } from "../../@types/Booking/companyDetails";
import * as DateFns from "date-fns";
import { format } from "date-fns";
import { MacroClassGroup } from "../../@types/DTO/MacroClass";
import i18n from "../../config/i18n";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";

//import { AllBookingApi } from "../../@types/API/BookingAvailableApi";

class BookingApi {
  allReservationSources: BookingSourceResponse[] = [];
  MacroClassList: MacroClassGroup[] = [];
  public bookingApiRequestInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL_2,
  });
  constructor() {
    this.getAllCountries();
    this.getStates("ITALIA");
  }

  getAllReservationSources(): Promise<BookingSourceResponse[]> {
    if (this.allReservationSources.length) {
      return Promise.resolve(this.allReservationSources);
    }
    return this.bookingApiRequestInstance
      .get<BookingSourceResponse[]>("/settings/channels")
      .then((e) => {
        this.allReservationSources = e.data;
        return e.data;
      });
  }
  getAllMacroGroups(): Promise<MacroClassGroup[]> {
    return this.bookingApiRequestInstance
      .get<MacroClassGroup[]>("/reservations/macroClassList")
      .then((e) => {
        return e.data;
      });
  }

  getBookingDetailsByVoucherCode(
    reservationVoucher: string,
    firstName: string,
    lastName: string,
    companyName: string
  ) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/by/voucher",
      {
        reservationVoucher: reservationVoucher,
        customerFirstName: firstName,
        customerLastName: lastName,
        companyName: companyName,
      }
    );
  }
  getReservationVehicles(bookingDetails: BookingDetails) {
    if (
      !bookingDetails.pickUpDateAndTime ||
      !bookingDetails.dropOffDateAndTime == null
    ) {
      return Promise.reject("Dates not found");
    }

    return this.bookingApiRequestInstance.post(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/touroperator/quotations",
      {
        // endDate: bookingDetails.dropOffDateAndTime?.toString(),
        // startDate: bookingDetails.pickUpDateAndTime?.toString(),
        endDate:
          DateFns.format(bookingDetails.dropOffDateAndTime, "yyyy-MM-dd") +
          "T" +
          DateFns.format(bookingDetails.dropOffDateAndTime, "HH:mm:ss"),
        startDate:
          DateFns.format(bookingDetails.pickUpDateAndTime, "yyyy-MM-dd") +
          "T" +
          DateFns.format(bookingDetails.pickUpDateAndTime, "HH:mm:ss"),
        // endDate: new Date(
        //   bookingDetails.dropOffDateAndTime || Date.now()
        // ).getTime(),
        // startDate: new Date(
        //   bookingDetails.pickUpDateAndTime || Date.now()
        // ).getTime(),
        age: 25,
        pickUpLocation: bookingDetails.pickUpLocationDetails.code,
        dropOffLocation: bookingDetails.dropOffLocationDetails.code,
        showPics: bookingDetails.showPics,
        showOptionalImage: bookingDetails.showOptionalImage,
        showVehicleParameter: bookingDetails.showVehicleParameter,
        showVehicleExtraImage: bookingDetails.showVehicleExtraImage,
        agreementCoupon: bookingDetails.agreementCoupon,
        macroGroupName: bookingDetails.macroGroupName,
      }
    );
  }
  getBookingDetailsByBookingDateAndReservationCode(
    reservationPrefix: string,
    reservationNumber: string,
    reservationDate: Date
  ) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/by/number",
      {
        reservationDate:
          DateFns.format(reservationDate, "yyyy-MM-dd") +
          "T" +
          DateFns.format(reservationDate, "HH:mm:ss"),
        reservationPrefix,
        reservationNumber: parseInt(reservationNumber),
      }
    );
  }
  registerNewUser(e) {
    return this.bookingApiRequestInstance.post<CreateNewBooking>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/register",
      {
        firstName: e.firstName,
        middleName: e.middleName,
        lastName: e.lastName,
        companyName: e.companyName,
        mobileNumber: e.mobileNumber,
        email: e.email,
        country: e.country,
        state: e.state,
        city: e.city,
        streetName: e.streetName,
        streetNumber: e.streetNumber,
        Zip: e.zip,
        vatNumber: e.vatNumber,
        ssnNumber: e.ssnNumber,
        sex: e.sex,
        telephone: e.telephone,
        birthDate: e.birthDate,
        birthPlace: e.birthPlace,
        birthProvidence: e.birthProvince,
        birthNation: e.birthNation,
        isDriver: e.isDriver,
        licence: e.license,
        issuedBy: e.issuedBy,
        licenceType: e.licenseType,
        documentIssueDate: e.documentIssueDate,
        documentExpiryDate: e.documentExpiryDate,
        documentNumber: e.documentNumber,
        document: e.document,
        customerId: e.customerId,
        customerType: e.customerType,
        password: e.password,
      }
    );
  }
  onlineUserAuthentication(
    //missing in proxy server
    email: string,
    password: string
  ): Promise<AxiosResponse<UserLoginResponse>> {
    // const RequestFormat = AllBookingApi.signIn(email, password);
    return this.bookingApiRequestInstance.post<UserLoginResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/login",
      {
        email,
        password,
      }
    );
  }

  addDrivers(
    reservationId: number,
    driverDetails: [DetailsFormValues, DetailsFormValues, DetailsFormValues]
  ): Promise<AxiosResponse<CustomerDetailsResponse_2>[]> {
    const driverDetails_filtered = driverDetails
      .map((e) => {
        if (!e) {
          return;
        }
        const temp = e;
        for (const i of Object.keys(temp)) {
          if (!temp[i]) {
            delete temp[i];
          }
        }
        return {
          ...temp,
          zip: parseInt(e.zip || ""),
          isDriver: e.isDriver.toLowerCase() == "yes",
          customerType: e.customerType.toUpperCase(),
        };
      })
      .filter((e) => {
        return !!e;
      });
    return (
      axios
        .post(
          process.env.REACT_APP_SERVER_URL_2 +
            "/driver/add/reservation/" +
            reservationId,
          {
            driverDetails: driverDetails_filtered,
          }
        )
        // chaining an api call to upload documents
        .then((e) => {
          e.data.map((e: CustomerDetailsResponse_2, index) => {
            // iterate through api response containing driver details
            if (driverDetails_filtered[index]?.documents.length) {
              // skip if not uploaded documents
              this.bookingApiRequestInstance.post(
                process.env.REACT_APP_SERVER_URL_2 +
                  "/driver/upload-documents/" +
                  e.customerId,
                driverDetails_filtered[index]?.documents.reduce(
                  // reduce to a form data with array of files in files key
                  (prev, current) => {
                    prev.append("files", current); // appending file in files key
                    return prev;
                  },
                  new FormData() // form data object
                )
              );
            }
          });
          return e.data;
        })
    );
  }
  async addDocuments(
    customerId: number,
    documents: Array<File>
  ): Promise<AxiosResponse<DocumentUploadResponse>> {
    const formData = new FormData();
    formData.append("files", "");
    documents.map((el) => formData.append("files", el));
    return this.bookingApiRequestInstance.put<DocumentUploadResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/documents/" + customerId,
      formData
    );
  }
  async getCustomerDetails(
    customerId: number
  ): Promise<AxiosResponse<CustomerDetailsResponse_2>> {
    return axios
      .get<CustomerDetailsResponse_2>(
        process.env.REACT_APP_SERVER_URL_2 + "/customer/" + customerId
      )
      .then((e) => {
        e.data.documents = [];
        return e;
      });
  }

  async updateCustomerDetails(
    customerId: number,
    customerDetails: DetailsFormValues
  ): Promise<AxiosResponse<CustomerDetailsResponse>> {
    const temp = formatUserDetails(customerDetails);
    // for (const i of Object.keys(temp)) {
    //   if (!temp[i]) {
    //     delete temp[i];
    //   }
    // }
    return this.bookingApiRequestInstance.put<CustomerDetailsResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/" + customerId,
      {
        ...temp,
        zip: parseInt(customerDetails.zip || ""),
        isDriver: customerDetails.isDriver?.toLowerCase() == "yes",
        customerType: customerDetails.customerType.toUpperCase(),
      }
    );
  }

  async getCity(cityName): Promise<AxiosResponse<StateResponse>> {
    return this.bookingApiRequestInstance.get<StateResponse>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/locations/city/" +
        cityName +
        "/state_and_zip/all"
    );
  }
  async getStates(countryName = ""): Promise<AxiosResponse<string[]>> {
    return this.bookingApiRequestInstance.get<string[]>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/locations/country/" +
        countryName +
        "/cities/all"
    );
  }
  async getReservationLocations(): Promise<AxiosResponse<Location[]>> {
    return this.bookingApiRequestInstance.get<Location[]>(
      process.env.REACT_APP_SERVER_URL_2 + "/reservations/locations/all/"
    );
  }

  async getAllCountries(): Promise<AxiosResponse<string[]>> {
    // console.log(this);

    return this.bookingApiRequestInstance.get<string[]>(
      process.env.REACT_APP_SERVER_URL_2 + "/locations/country/all"
    );
  }
  async getItalianCustomerDetailsBySSN(
    ssn: string
  ): Promise<AxiosResponse<ItalianCustomerDetailsUsingSSNResponse>> {
    return this.bookingApiRequestInstance.get<ItalianCustomerDetailsUsingSSNResponse>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/details/by/ssn/" + ssn
    );
  }
  async getSsnByCustomerDetails(
    firstName: string,
    lastName: string,
    birthNation: string,
    birthPlace: string,
    sex: string,
    dob: Date,
    country: string
  ): Promise<AxiosResponse<SsnByItalianCustomerDetails>> {
    return this.bookingApiRequestInstance.post<SsnByItalianCustomerDetails>(
      process.env.REACT_APP_SERVER_URL_2 + "/customer/details_by_ssn",
      {
        firstName,
        lastName,
        birthNation: birthNation,
        birthPlace: birthPlace,
        sex: sex.toUpperCase(),
        dob: new Date(dob).toISOString(),
        national: country,
      }
    );
  }
  savePaymentForNexi(paymentDetails: NexiChargeTransactionDetails) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 + `/reservations/handlePayments`,
      {
        ...paymentDetails,
      }
    );
  }
  sendNexiDetailsToMyRent(
    reservationId: number,
    nexiDetails: NexiChargeTransactionDetails
  ) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 +
        `/reservation/touroperator/${reservationId}/importNexiChargeTransaction`,
      {
        ...nexiDetails,
      }
    );
  }
  async getPrivacyPolicy(
    reservationId: number
  ): Promise<AxiosResponse<PrivacyPolicy>> {
    return this.bookingApiRequestInstance.get<PrivacyPolicy>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/privacy-policy/" +
        reservationId
    );
  }
  async savePrivacyPolicy(
    reservationId: number,
    acceptedPolicies: number[]
  ): Promise<AxiosResponse<PrivacyPolicy>> {
    return this.bookingApiRequestInstance.post<PrivacyPolicy>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/privacy-policy/" +
        reservationId,
      {
        acceptedPrivacyPolicies: acceptedPolicies,
      }
    );
  }
  async createBooking(
    reservationInfo: BookingDetails,
    customerInfo: DetailsFormValues,
    vehicleInfo: Vehicle,
    paymentInfo: SelectedVehicleDetails,
    paymentMethod: string,
    CompanyInfo: companyDetails,
    userId: string | undefined
  ): Promise<AxiosResponse<ReservationSuccessfullResponse>> {
    return this.bookingApiRequestInstance.post<ReservationSuccessfullResponse>(
      process.env.REACT_APP_SERVER_URL_2 +
        "/reservations/touroperator/bookings",
      {
        reservationInfo: {
          endDate:
            DateFns.format(reservationInfo.dropOffDateAndTime, "yyyy-MM-dd") +
            "T" +
            DateFns.format(reservationInfo.dropOffDateAndTime, "HH:mm:ss"),
          startDate:
            DateFns.format(reservationInfo.pickUpDateAndTime, "yyyy-MM-dd") +
            "T" +
            DateFns.format(reservationInfo.pickUpDateAndTime, "HH:mm:ss"),
          pickUpLocation: reservationInfo.pickUpLocationDetails.code,
          dropOffLocation: reservationInfo.dropOffLocationDetails.code,
          bookingChannel: reservationInfo.reservationSource.code,
          agreementCoupon: reservationInfo.agreementCoupon,
          discountValueWithoutVat: reservationInfo.discountValueWithoutVat,
        },
        customerInfo: {
          ...formatUserDetails(customerInfo),
          isDriver: false,
        },
        vehicleInfo: {
          VehicleCode: vehicleInfo.Vehicle?.Code,
        },
        paymentMethod: paymentMethod,
        insuranceId: paymentInfo.selectedInsurance?.insuranceId,
        Fee: {
          Amount: (
            Object.values(paymentInfo.addedExtraOptions).reduce((prev, e) => {
              return prev + e.price * e.quantity;
            }, 0) +
            (paymentInfo.selectedInsurance?.price || 0) +
            paymentInfo.price
          ).toString(),
          CurrencyCode: "EUR",
        },
        isEndUserBooking: "true", // to be enabled if payment method is not wiretransfer,
        onlineUser: { id: userId },
        bookingLang: i18n.language,
        CompanyInfo: {
          CompanyPhoneNumber: CompanyInfo.CompanyPhoneNumber,
          CompanyEmail: CompanyInfo.CompanyEmail,
          CompanyEInvoicingCode: CompanyInfo.CompanyEInvoicingCode,
          CompanyEInvoicingEmail: CompanyInfo.CompanyEInvoicingEmail,
          CompanyBirthDate: CompanyInfo.CompanyBirthDate,
          CompanyBirthCity: CompanyInfo.city,
          CompanyBirthProv: CompanyInfo.CompanyBirthProv,
          CompanyBirthCountry: CompanyInfo.country,
          CompanyStreet: CompanyInfo.CompanyStreet,
          CompanyStreetNumber: CompanyInfo.CompanyStreetNumber,
          CompanyCityName: CompanyInfo.city,
          CompanyPostalCode: CompanyInfo.CompanyPostalCode,
          CompanyStateProv: CompanyInfo.CompanyBirthProv,
          CompanyCountry: CompanyInfo.country,
          CompanyName: CompanyInfo.CompanyName,
        },
        optionals: Object.values(paymentInfo.addedExtraOptions).map((e) => {
          return {
            Equiptype: e.name,
            Quantity: e.quantity.toString(),
            price: parseInt(e.price.toString()),
          };
        }),
      }
    );
  }

  getAllPaymentMethods() {
    return axios
      .get<AllPaymentMethodsResponse>(
        process.env.REACT_APP_SERVER_URL_2 + "/settings/payments"
      )
      .then((e) => {
        return e.data;
      });
  }

  getPaymentLink(reservationId: number, amount: number) {
    return axios
      .get<PaymentLinkResponse>(
        process.env.REACT_APP_SERVER_URL_2 +
          `/settings/paymentLink/${reservationId}/${amount}`
      )
      .then((e) => {
        return e.data;
      });
  }
  getAppSetting() {
    return axios
      .get<AppSettingResponse>(
        process.env.REACT_APP_SERVER_URL_2 + `/settings/app`
      )
      .then((e) => {
        return e.data;
      });
  }

  createNewUser(e) {
    return axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 + "/customer/registerBookingUser",
        {
          firstName: e.firstName,
          lastName: e.lastName,
          phoneNumber: e.phoneNumber,
          email: e.email,
          password: e.password,
          birthDate: e.birthDate,
          onlineUserType: 3,
          onlineUserRole: 2,
          onlineUserStatus: 1,
        }
      )
      .then(function (response) {
        //  console.log(response);
        return response;
      });
  }

  loginUser(e) {
    return axios
      .post(process.env.REACT_APP_SERVER_URL_2 + "/customer/login", {
        email: e.email,
        password: e.password,
      })
      .then(function (response) {
        //   console.log(response);
        return response;
      });
  }

  getBookingDetailsbyUserId(userid: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/bookinglist/by/userid/${userid}/reservation`
    );
  }

  UpdateUserProfileById(id, e) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/onlineUser/updateOnlineUser/${id}`,
      {
        firstName: e.firstName,
        lastName: e.lastName,
        phoneNumber: e.phoneNumber,
        userImage: e.userImage,
      }
    );
  }

  getUserProfileByUserId(userid: number) {
    return this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 + `/customer/getUserProfile/${userid}`
    );
  }

  async getCustomerDeatilsByUserid(userid: number) {
    return await this.bookingApiRequestInstance.get(
      process.env.REACT_APP_SERVER_URL_2 +
        `/customer/onlineUser/${userid}/customer`
    );
  }
  async updateCustomerDetailByCustomerId(id, cid, data) {
    return await axios
      .put(process.env.REACT_APP_SERVER_URL_2 + `/customer/${id}/${cid}`, {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        companyName: data.companyName,
        mobileNumber: data.mobileNumber,
        email: data.email,
        country: data.country,
        state: data.state,
        city: data.city,
        streetName: data.street,
        streetNumber: data.streetNumber,
        zip: data.zip,
        vatNumber: data.vatNumber,
        ssnNumber: data.ssnNumber,
        sex: data.sex,
        telephone: data.telephone,
        dateOfBirth: data.dateofBirth,
        birthPlace: data.birthPlace,
        birthProvince: data.birthProvidence,
        birthNation: data.birthNation,
        isDriver: data.isDriver,
        license: data.licence,
        issuedBy: data.issuedBy,
        releaseDate: data.releaseDate,
        licenseType: data.licenceType,
        documentIssueDate: data.documentIssueDate,
        documentExpiryDate: data.documentExpiryDate,
        documentNumber: data.documentNumber,
        document: data.document,
        customerId: data.customerId,
        customerType: data.customerType,
      })
      .then((e) => {
        return e;
      });
  }

  async sentVerificationcode(e) {
    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 + `/customer/login/sendVerification`,
        {
          email: e,
        }
      )
      .then((e) => {
        return e.data;
      })
      .catch((err) => {
        return err;
      });
  }
  async forgotPassword(otp) {
    return await axios
      .post(
        process.env.REACT_APP_SERVER_URL_2 +
          `/customer/login/verifyOtpChangePassword`,
        {
          verificationCode: otp.verificationCode,
          emailId: otp.email,
          newPassword: otp.newPassword,
        }
      )
      .then((e) => {
        return e.data;
      })
      .catch((err) => {
        return err;
      });
  }

  getInsuranceDetails(
    reservationDetails: BookingDetails,
    vehicleGroup: string
  ) {
    return axios
      .post<InsuranceDetails>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/reservations/touroperator/getInsuranceList",
        {
          startDate: reservationDetails.pickUpDateAndTime,
          endDate: reservationDetails.dropOffDateAndTime,
          reservationSource:
            reservationDetails.reservationSource.code || "WEB001",
          group: vehicleGroup,
          locationCode: reservationDetails.pickUpLocationDetails.code,
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  sendEmailForWireTransfer(id: number) {
    return axios.post(
      process.env.REACT_APP_SERVER_URL_2 +
        `/reservations/touroperator/${id}/sendEmail`
    );
  }
  getPrivacyAndTerms() {
    return axios
      .get<PrivacyTerms>(
        process.env.REACT_APP_SERVER_URL_2 +
          "/reservations/touroperator/getPrivacyAndTerms/" +
          i18n.language
      )
      .then((e) => {
        return e.data;
      });
  }
}
function formatUserDetails(
  customerInfo: DetailsFormValues
): Record<string, any> {
  const tempCustomerInfo: Record<string, any> = { ...customerInfo };
  for (const i in tempCustomerInfo) {
    if (tempCustomerInfo[i] == "") {
      delete tempCustomerInfo[i];
    } else if (i == "zip" && tempCustomerInfo[i]) {
      tempCustomerInfo[i] = parseInt((tempCustomerInfo[i] || "").toString());
    } else if (i == "ssnNumber" && tempCustomerInfo[i]) {
      tempCustomerInfo[i] = (tempCustomerInfo[i] || "").toString();
    }
    // console.log(tempCustomerInfo);
  }
  return tempCustomerInfo;
}

const BookingApiInstance: BookingApi = new BookingApi();
export { BookingApiInstance as default, BookingApiInstance as BookingApi };
