import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import {
  FooterConfiguration,
  HeaderConfiguration,
} from "../../@types/Pages/PageConfiguration";
import theme from "../../config/MuiColorThemes";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { PageConfigActions } from "../../store/PageConfigurations";
import React from "react";

export function EmbedPage() {
  const dispatch = useDispatch();
  theme.palette["primary-2"].main = "#c4c4c4";
  dispatch(
    PageConfigActions.setChooseVehicleConfig({
      buttonColors: "#ffffff",
      payNowButtonColor: "#bb3333",
      payLaterButtonColor: "",
      priceColor: "#004188",
      headingColor: "",
      specificationColor: "",
      backGroundColor: "",
      outlineColor: "",
    })
  ); //button color
  dispatch(
    PageConfigActions.setHomePageConfig({
      descriptionTextClasses: "text-white",
    })
  );
  dispatch(
    PageConfigActions.setHeaderConfig({
      isHidden: true,
    } as HeaderConfiguration)
  );
  dispatch(
    PageConfigActions.setFooterConfig({
      isHidden: true,
    } as FooterConfiguration)
  );
  dispatch(BOOKING_ACTIONS.setIsEmbedded(true));
  return (
    <div>
      <Outlet />
    </div>
  );
}
