import {
  BookingVehicleInfoCardProps,
  ChosenOptions,
  VehicleSpecsCardProps,
} from "./BookingVehicleInfoCard.props";
import React, { createContext, useEffect, useState } from "react";

import AppButton from "../../UI/AppButtons/AppButton";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Carousel } from "../Carousel/Carousel";
import Insurance from "../../../API/responses/Insurance.json";
import { InsuranceCardsLayout } from "../Insurance/Insurance";
import { Modal } from "../../UI/AppModal";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import SelectVehicleExtra from "../VehicleExtraSelections/SelectVehicleExtra";
import { TabView } from "../../UI/AppTabView";
import { VehicleSpecification } from "../VehicleSpecification/VehicleSpecification";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import theme from "../../../config/MuiColorThemes";
import { SelectedVehicleDetails } from "../../../@types/Booking/SelectedVehicleDetails";
import { differenceInDays } from "date-fns";
import BookingApi from "../../../API/Apis/BookingApi";
import { InsuranceDetails } from "../../../@types/DTO/ApiResponses";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { t } from "i18next";

function BookingVehicleInfoCard({
  status = "Unavailable",

  ...VehicleDetails
}: BookingVehicleInfoCardProps) {
  const ReservationDetails = BookingGetters.getBookingDetails();
  const chooseVehicleConfig = PageConfigGetters.getChooseVehicleConfig();
  theme.palette["chooseVehiclePayNowButton"].main =
    chooseVehicleConfig.payNowButtonColor;

  theme.palette["addInsuranceButton"].contrastText = theme.palette[
    "addExtrasButton"
  ].contrastText = chooseVehicleConfig.buttonColors;
  const rentalTerms = BookingGetters.getRentalTerms();
  const styles = {
    specificationBorder: "border-2 border-solid border-gray-100",
    textColor: `text-[${chooseVehicleConfig.priceColor}]`,
  };

  const [activeTab, setActiveTab] = useState<string>("0");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [conditionalButton, setConditionalButton] = useState<Boolean>(false);
  const [selectedFields, setSelectedFields] = useState<ChosenOptions>({
    insurance: undefined,
    options: {},
  });
  const [numberOfDays] = useState(
    differenceInDays(
      new Date(ReservationDetails.dropOffDateAndTime || Date.now()),
      new Date(ReservationDetails.pickUpDateAndTime || Date.now())
    )
  );
  const [pricePerDay] = useState(
    parseFloat(VehicleDetails.priceDetails.payNow.toString()) / numberOfDays
  );
  const [totalPricePerDay, setTotalPricePerDay] = useState(pricePerDay);
  const [totalPrice, setTotalPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payNow) || 0
  );
  const [payLaterPrice, setPayLaterPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payLater) || 0
  );
  const [insuranceList, setInsuranceList] = useState<
    InsuranceDetails | string | number | boolean
  >({
    "Booking Insurance": [],
    Specification: [],
    // isInsuranceAvailable: [],
  });
  const [isInsuranceAvailable, setIsInsuranceAvailable] =
    useState<InsuranceDetails>();
  // boolean | string | number | undefined // hardcoding the insurance set to false for message display
  const [paymentType, setPaymentType] = useState<"PAYNOW" | "PAYLATER">(
    "PAYNOW"
  );
  const [showButton, setShowButton] = useState<boolean>();
  const bookingVehicleDetails = BookingGetters.getBookingDetails();
  useEffect(() => {
    // setShowButton(true);

    BookingApi.getAppSetting().then((e) => {
      // console.log(e.showOnePayMethodTwoPrice);
      setShowButton(e.showOnePayMethodTwoPrice);
      //setShowButton(true);
    });
    //console.log(bookingVehicleDetails, VehicleDetails.vehicleGroup);
    // console.log(bookingVehicleDetails, VehicleDetails.vehicleGroup);
    //console.log(VehicleDetails.Vehicle.groupPic.national.code);
    BookingApi.getInsuranceDetails(
      bookingVehicleDetails,
      VehicleDetails.vehicleGroup
    ).then((e) => {
      setInsuranceList(e);
      setIsInsuranceAvailable(e);
    });
  }, []);

  useEffect(() => {
    let price: number = parseFloat(VehicleDetails.priceDetails.payNow);
    if (selectedFields.insurance) {
      price += parseFloat(selectedFields.insurance.price.toString());
    }
    if (selectedFields.options) {
      Object.values(selectedFields.options).map((el) => {
        price += el.price * el.quantity;
      });
    }
    // console.log(price);
    setTotalPricePerDay(price / numberOfDays);
    setTotalPrice(price);
    setPayLaterPrice(price);
  }, [selectedFields]);
  function PayLaterButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        style={{ borderWidth: "0.1rem" }}
        variant="outlined"
        color="primary-3"
        className="w-fit min-h-12 "
        clickHandler={() => {
          setPaymentType("PAYLATER");
          if (!selectedFields.insurance) {
            setActiveTab("1");
            setShowModal(true);
          } else {
            VehicleDetails.moveToPayment({
              type: paymentType,
              price: payLaterPrice,
              addedExtraOptions: selectedFields.options,
              selectedInsurance: selectedFields.insurance,
            });
          }
        }}
      >
        <p
          className={
            "min-w-32 w-full h-full flex items-center justify-center md:text-xl text-xs capitalize"
          }
        >
          {t("Pay Later")}
        </p>
      </AppButton>
    );
  }

  function PayNowButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="bookingButtonColor"
        className="w-full min-h-12"
        clickHandler={() => {
          // console.log(totalPrice);
          setPaymentType("PAYNOW");
          setConditionalButton(false);
          if (!selectedFields.insurance) {
            setActiveTab("1");
            setShowModal(true);
          } else {
            VehicleDetails.moveToPayment({
              type: paymentType,
              price: totalPrice,
              addedExtraOptions: selectedFields.options,
              selectedInsurance: selectedFields.insurance,
            });
          }
        }}
      >
        <p className="min-w-32 w-full h-full flex items-center justify-center  text-white md:text-xl text-xs capitalize  ">
          {t("Pay Now")}
        </p>
      </AppButton>
    );
  }

  function BookButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="addInsuranceButton"
        className="w-full min-h-12"
        clickHandler={() => {
          // console.log(totalPrice);
          setPaymentType("PAYNOW");
          setConditionalButton(false);
          if (!selectedFields.insurance) {
            setActiveTab("1");
            setShowModal(true);
          } else {
            VehicleDetails.moveToPayment({
              type: paymentType,
              price: totalPrice,
              addedExtraOptions: selectedFields.options,
              selectedInsurance: selectedFields.insurance,
            });
          }
        }}
      >
        <p className="min-w-32 w-full h-full flex items-center justify-center  text-white md:text-xl text-xs capitalize  ">
          {t("Book Now")}
        </p>
      </AppButton>
    );
  }
  function AddExtrasButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="addExtrasButton"
        clickHandler={() => {
          setActiveTab("0");
          setShowModal(true);
          setConditionalButton(true);
        }}
      >
        <p
          className={
            "w-full h-full flex items-center justify-center md:text-md text-xs"
          }
        >
          {t("Add Extras")}
        </p>
      </AppButton>
    );
  }
  function AddInsuranceButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="addInsuranceButton"
        clickHandler={() => {
          setActiveTab("1");
          setShowModal(true);
          setConditionalButton(true);
        }}
      >
        <p
          className={
            "w-full h-full flex items-center justify-center md:text-md text-xs"
          }
        >
          {t("Add Insurance")}
        </p>
      </AppButton>
    );
  }
  return (
    <section
    // className="hover:drop-shadow-lg cursor-pointer  flex flex-col bg-white  box-border w-full h-full font-roboto text-[#004188]"
    >
      <header
      // className={"text-black w-full  flex md:flex-row flex-col p-2 px-4"}
      >
        <main className="w-full">
          <header className="flex flex-col">
            <h2 className="font-bold box-border py-4 md:text-3xl text-2xl">
              {VehicleDetails.vehicleName.split(" or ")[0]}
              <small style={{ fontSize: "12px" }}> o Similare</small>
            </h2>
            {(() => {
              const amountDetails = formatPriceWithCurrencySymbol(
                BookingGetters.getCurrency(),
                totalPricePerDay
              );
              return (
                <span className="main-price  text-inherit md:text-4xl text-md">
                  <p className={"whitespace-nowrap " + styles.textColor}>
                    {amountDetails.symbol + " " + amountDetails.price}
                    <sub className="font-normal h-full">/day</sub>
                  </p>
                </span>
              );
            })()}
            {(() => {
              const amountDetails = formatPriceWithCurrencySymbol(
                BookingGetters.getCurrency(),
                totalPrice
              );
              return (
                <span className="total-price md:text-xl text-sm text-gray-500  whitespace-nowrap">
                  <span>Total: </span>
                  <span>{amountDetails.symbol + " "}</span>
                  <span>{amountDetails.price}</span>
                </span>
              );
            })()}
          </header>
          <aside>
            {/* <div className={" w-24"}>
            <div className={"w-full h-48"}>
              <Carousel
                carouselItems={VehicleDetails.vehicleImages.map(
                  (arrEl, index) => {
                    return (
                      <img
                        alt={""}
                        src={arrEl}
                        key={index}
                        className={"w-full h-full object-contain"}
                      />
                    );
                  }
                )}
                onSlideItemClick={(index: number) => {
                  setSelectedImage(index);
                  // console.log(index);
                }}
              />
            </div>
          </div> */}
            <div className="cont-img">
              {selectedImage >= 0 && (
                <img
                  src={VehicleDetails.vehicleImages[selectedImage]}
                  alt={""}
                />
              )}
            </div>
          </aside>
          <footer className={"flex md:flex-row flex-col flex-wrap  gap-8 grow"}>
            <div className="contenitore-punti">
              {VehicleDetails.specifications.slice(0, 3).map((el, index) => {
                return (
                  <div key={index} className="punti">
                    <VehicleSpecsCard {...el} />
                  </div>
                );
              })}

              {/* <div className="flex flex-col flex-wrap w-fit gap-4">
                {VehicleDetails.specifications.slice(4, 6).map((el, index) => {
                  return (
                    <div key={index} className="punti">
                      <VehicleSpecsCard {...el} />
                    </div>
                  );
                })}
                {VehicleDetails.specifications.length > 6 && (
                  <span
                    className="text-blue-800 underline"
                    onClick={() => {
                      setActiveTab("3");
                      setShowModal(true);
                    }}
                  >
                    more specs
                  </span>
                )}
              </div> */}
            </div>
            <main className="w-full">
              {/* come richiesto nella MAIL 21/04/2023 commento i due pulsanti */}
              {/* <div className="pulsante-extra">
                <AddExtrasButton />
              </div>
              <div className="pulsante-assistenza">
                <AddInsuranceButton />
              </div> */}
              <div className="pulsante-pay-now-or-book">
                {showButton ? <PayNowButton /> : <BookButton />}
              </div>
            </main>
          </footer>
        </main>
      </header>

      <footer className="flex flex-wrap gap-3 box-border p-4 items-center">
        <div className={"md:flex flex-wrap gap-2 hidden"}>
          {/* come richiesto nella MAIL 21/04/2023 commento i due pulsanti */}
          {/* <AddExtrasButton /> */}
          {/* <AddInsuranceButton /> */}
        </div>
        <div className="grow flex  gap-3 justify-end md:order-none order-first">
          <div>
            {(() => {
              const amountDetails = formatPriceWithCurrencySymbol(
                BookingGetters.getCurrency(),
                payLaterPrice
              );
              return (
                <span
                  className={
                    "total-price text-gray-500 flex items-center justify-center h-full w-full " +
                    styles.textColor +
                    " md:text-4xl text-xl "
                  }
                >
                  {amountDetails.symbol + " "}
                  {amountDetails.price}
                </span>
              );
            })()}
          </div>
          {showButton ? <PayLaterButton /> : ""}
        </div>
      </footer>
      {showModal && (
        <Modal
          isOpen={true}
          handleClose={() => {
            setShowModal(() => false);
          }}
        >
          <div className="h-full w-full">
            <div className={"h-[65vh] overflow-auto"}>
              <TabView
                handleChange={(e, tabIndex) => {
                  // console.log(tabIndex);
                  setActiveTab(tabIndex);
                }}
                activeTabValue={activeTab}
                tabs={[
                  {
                    title: t("Add Extras"),
                    // title: "Extra Options",
                    content: (
                      <div className={"h-full"}>
                        <SelectVehicleExtra
                          values={selectedFields.options || {}}
                          onChange={(e: any) => {
                            setSelectedFields((prev) => {
                              return {
                                ...prev,
                                options: e,
                              };
                            });
                          }}
                          options={VehicleDetails.extras}
                        />
                      </div>
                    ),
                  },
                  {
                    // title: "Image Gallery",
                    title: t("Add Insurance"),
                    content:
                      // <>
                      //   <AppRadioButton
                      //     options={insuranceList.Specification.map((e) => {
                      //       return e["Booking Insurance"] || e["Description"];
                      //     })}
                      //     label="Choose Insurance"
                      //     isInRow={false}
                      //     value={selectedFields.insurance?.details || ""}
                      //     onChange={(e) => {
                      //       setSelectedFields((prev) => {
                      //         prev.insurance = {
                      //           details: e,
                      //           price: parseFloat(
                      //             parseFloat(
                      //               //Insurance[e].pricePerDayInUSD
                      //               "0"
                      //             ).toFixed(2)
                      //           ),
                      //         };

                      //         return {
                      //           ...prev,
                      //         };
                      //       });
                      //     }}
                      //   />
                      // </>
                      isInsuranceAvailable?.Specification?.[0] ? (
                        <InsuranceCardsLayout
                          selectedInsuranceIndex={insuranceList[
                            "Booking Insurance"
                          ].findIndex(
                            (e) =>
                              e.Description.toLowerCase() ===
                              selectedFields.insurance?.details.toLowerCase()
                          )}
                          onSelected={(e) => {
                            setSelectedFields((prev) => {
                              if (e < 0) {
                                delete prev.insurance;
                              } else {
                                prev.insurance = {
                                  details:
                                    insuranceList["Booking Insurance"][e]
                                      .Description,
                                  insuranceId:
                                    insuranceList["Booking Insurance"][e]
                                      .Description,
                                  price: parseFloat(
                                    parseFloat(
                                      insuranceList["Booking Insurance"][
                                        e
                                      ].Amount.toString()
                                    ).toFixed(2)
                                  ),
                                };
                              }
                              return {
                                ...prev,
                              };
                            });
                          }}
                          insurances={
                            insuranceList["Booking Insurance"]?.map((e) => {
                              return {
                                name: e.Description,
                                pricePerDayInUSD: e.Amount.toString(),
                                features: e.Specification,
                                isRecommended: e.isRecommended,
                              };
                            }) || []
                          }
                        />
                      ) : (
                        <p className="font-semibold text-black text-align-center">
                          Nessuna Copertura disponibile
                        </p>
                      ),
                  },
                  {
                    // title: "Rental Terms",
                    title: t("Rental Terms"),
                    content: (
                      <p className="h-full overflow-auto text-justify box-border px-4">
                        {rentalTerms}
                      </p>
                    ),
                  },
                  {
                    // title: "All Specifications",
                    title: t("All Specifications"),
                    content: (
                      <div className="flex flex-row flex-wrap gap-2 h-full overflow-auto">
                        {VehicleDetails.specifications.map((el, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                "w-32 h-32 flex flex-col items-center justify-center gap-4" +
                                " " +
                                styles.specificationBorder
                              }
                            >
                              <VehicleSpecification {...el} />
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className={"flex flex-col items-center justify-center"}>
              {conditionalButton === true ? (
                <AppButton
                  className={"box-border py-4 w-fit"}
                  clickHandler={() => {
                    setShowModal(() => false);
                  }}
                  color={"addInsuranceButton"}
                >
                  {t("Proceed")}
                </AppButton>
              ) : (
                <AppButton
                  color={"addInsuranceButton"}
                  className={"box-border py-4 w-fit"}
                  clickHandler={() => {
                    VehicleDetails.moveToPayment({
                      type: paymentType,
                      price: payLaterPrice,
                      addedExtraOptions: selectedFields.options,
                      selectedInsurance: selectedFields.insurance,
                    } as SelectedVehicleDetails);
                  }}
                >
                  {t("Proceed")}
                </AppButton>
              )}
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
}

function VehicleSpecsCard({ iconUrl, value }: VehicleSpecsCardProps) {
  return (
    <div>
      <div className="flex items-center">
        {/* <img className="h-6 w-6" src={iconUrl} alt={""} /> */}
      </div>
      <span className="h-fit flex items-center md:text-lg text-md">
        &bull; {value}
      </span>
    </div>
  );
}
export { BookingVehicleInfoCard };
