import AppButton from "../../UI/AppButtons/AppButton";
import AppCards from "../../UI/AppCards";
import { LocalGasStationOutlined } from "@mui/icons-material";
import {
  DropOffReservationDetailsProps,
  PickUpReservationDetailsProps,
  ReservationDetailsProps,
} from "./ReservationCard.props";
//import TransmissionLogo from "../../../assets/icons/manual-transmission.svg";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import React, { memo, useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";

function ReservationCard() {
  const [noOfBooking, setNoOfBooking] = useState<number>(0);

  const [reservationList, setReservationList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    BookingApi.getBookingDetailsbyUserId(id).then((e) => {
      setNoOfBooking(e.data.total);
      e.data.reservationList.forEach((e) => {});
      setReservationList(e.data.reservationList);
      setLoader(false);
    });
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "http://autoonetest.myrent.it/MyRentWeb/api/v1/onlineUser/17/invoices",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            tokenValue: "5094bd3060c4f07e2da8ccc67ee3b2e4",
          },
        }
      )
      .then((res) => {
        setData(res.data);
        // console.log("Result:", res.data.invoiceList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* {noOfBooking < 1 ? ( */}
      {loader ? (
        <Typography className="!text-center  !text-3xl !flex !justify-center  ">
          Loading...
        </Typography>
      ) : (
        // <button
        //   className="bg-[#be1e1e]"
        //   onFocus={fetchHandleChange}
        //   disabled={loader}
        // >
        <TableContainer>
          <Table
            className="!border-2  rounded-2xl"
            aria-label="simple table"
            stickyHeader
          >
            <TableHead className="!bg-[#020D53] text-[24px] ">
              <TableRow className="!bg-[#020D53] rounded-2xl">
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Customer Detalis
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px] ">
                  Pick up location
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Drop off location
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Reservation Date
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Vehicle details
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Invoice
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationList.map((e: any, i) => (
                <TableRow key={i}>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li className="!text-[#333]">
                        {e.clientiByIdCliente.description
                          .split("/")[0]
                          .split("-")
                          .reverse("")
                          .join(" ") || "No Data"}
                      </li>
                      <li className="text-[#838181] text-[12px]">
                        {e.email || "no data"}
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell className="!text-[14px]">
                    <ul>
                      <li>
                        {e.locationPickupExcepted.description.split("-")[0] ||
                          "NO Data"}
                      </li>
                      <li className="text-[#838181] text-[12px] mx-12">
                        {e.startDate.slice(11, 19) || "no data"}
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell className="!text-[14px]">
                    <ul>
                      <li>
                        {e.locationReturnExcepted.description.split("-")[0] ||
                          "NO Data"}
                      </li>
                      <li className="text-[#838181] text-[12px] mx-12">
                        {e.endDate.slice(11, 19) || "No Data"}
                      </li>
                    </ul>
                  </TableCell>

                  <TableCell className="!text-[14px]">
                    {`${new Date(e.startDate).getDate()}-0${
                      new Date(e.startDate).getMonth() + 1
                    }-${new Date(e.startDate).getFullYear()}`}{" "}
                    -{" "}
                    {`${new Date(e.endDate).getDate()}-0${
                      new Date(e.endDate).getMonth() + 1
                    }-${new Date(e.endDate).getFullYear()}`}
                  </TableCell>
                  <TableCell className="!text-[14px]">
                    {e.vehicle || "no data"}
                  </TableCell>
                  <TableCell>
                    <button className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow ">
                      Invoice{" "}
                      <img
                        className="h-6"
                        src="/assets/images/Invoice.svg"
                        alt="Invoice Icon"
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        //   data
        // </button>
      )}
    </>
  );
}
// ReservationDetails.propTypes = {
//   ...ReservationDetailsProps,
// };
// function ReservationDetails({ logoSrc, dateAndTime, locationName }) {
//   const textColor = "text-gray-600";
//   return (
//     <div className={"flex flex-row flex-wrap" + " " + textColor}>
//       <aside className="flex flex-col h-20 w-20 box-border px-4">
//         <img src={logoSrc} className={textColor} />
//         <section className="vechile-details"></section>
//       </aside>
//       <main className="grow">
//         <h2 className="font-bold">{dateAndTime.toDateString()}</h2>
//         <h2 className="font-bold">{dateAndTime.toLocaleTimeString()}</h2>
//         <p>{locationName}</p>
//       </main>
//     </div>
//   );
// }
// PickUpReservationDetails.propTypes = { ...PickUpReservationDetailsProps };
// function PickUpReservationDetails({ dateAndTime, locationName }) {
//   return (
//     <ReservationDetails
//       logoSrc="/assets/images/pick-icon.svg"
//       locationName={locationName}
//       dateAndTime={new Date(dateAndTime)}
//     />
//   );
// }
// DropOffReservationDetails.propTypes = { ...DropOffReservationDetailsProps };
// function DropOffReservationDetails({ dateAndTime, locationName }) {
//   return (
//     <ReservationDetails
//       logoSrc="/assets/images/drop-icon.svg"
//       locationName={locationName}
//       dateAndTime={new Date(dateAndTime)}
//     />
//   );
// }
// function ReservationPrice({ price, numberOfDays }) {
//   return (
//     <div className="flex flex-col box-border">
//       {/* <h2 className="text-xl text-zinc-700">Total {numberOfDays} Days</h2> */}
//       <h2 className="text-xl text-zinc-700">Totale {numberOfDays} giorni</h2>
//       <p>
//         <span className="text-3xl font-bold">
//           {formatPriceWithCurrencySymbol(BookingGetters.getCurrency()).symbol}
//         </span>{" "}
//         <span className="font-bold text-5xl">{price}</span>
//       </p>
//       {/* <p>(Exta options Included)</p> */}
//       <p>(Opzioni aggiuntive incluse)</p>
//     </div>
//   );
// }
export default memo(ReservationCard);
