import * as AllIcons from "@mui/icons-material";
import { SvgIconComponent } from "@mui/icons-material";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React from "react";

import { BookingGetters } from "../../../store/onlineBooking/getters";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";

export function ReservationDetailsPreview(): JSX.Element {
  const ReservationDetails = BookingGetters.getBookingDetails();
  const { vehicleDetails, paymentDetails } =
    BookingGetters.getSelectedVehicleDetails();
  const UserDetails = BookingGetters.getUserDetails();
  const currency = "EUR";
  function DetailsCard({
    primary,
    secondary,
    ImageIcon,
  }: {
    primary: string;
    secondary: string;
    ImageIcon?: SvgIconComponent;
  }) {
    return (
      <ListItem style={{ width: "fit-content" }}>
        {ImageIcon && (
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <ImageIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  }
  return (
    <div>
      <div className="box-border px-8 flex flex-row items-center justify-center text-4xl">
        <h2 className="font-semibold grow text-right box-border pr-12">
          Total
        </h2>
        <div className=" text-red-500 font-bold grow text-left">
          {
            formatPriceWithCurrencySymbol(currency, paymentDetails?.price || 0)
              .original
          }
        </div>
      </div>
      <List>
        <ListSubheader style={{ fontSize: "1.2rem" }}>
          Addons Details
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-4 grid-cols-1 gap-0">
          {Object.values<SelectedVehicleExtraOptions>(
            paymentDetails?.addedExtraOptions || []
          ).length ? (
            Object.values<SelectedVehicleExtraOptions>(
              paymentDetails?.addedExtraOptions || []
            ).map((e: SelectedVehicleExtraOptions, index) => {
              const total = e.price * e.quantity;
              return (
                <DetailsCard
                  key={index}
                  primary={e.name}
                  secondary={
                    formatPriceWithCurrencySymbol(currency, e.price).original +
                    " x " +
                    e.quantity +
                    "(U) = " +
                    formatPriceWithCurrencySymbol(currency, total).original
                  }
                />
              );
            })
          ) : (
            <ListItem>N/A</ListItem>
          )}
        </div>
      </List>
      <List>
        <ListSubheader style={{ fontSize: "1.2rem" }}>
          Vehicle Details
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-4 lg:gap-4 grid-cols-1 gap-0">
          <DetailsCard
            ImageIcon={AllIcons.AccountCircle}
            primary={"Name"}
            secondary={vehicleDetails?.Vehicle?.VehMakeModel?.Name || "N/A"}
          />
        </div>
      </List>
      <List dense>
        <ListSubheader style={{ fontSize: "1.2rem" }}>
          Reservation Details
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-4 grid-cols-1 gap-0">
          <DetailsCard
            ImageIcon={AllIcons.Today}
            primary={"Pick up Date and Time"}
            secondary={
              ReservationDetails?.pickUpDateAndTime.toString() || "N/A"
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.LocationOn}
            primary={"Pick up location name"}
            secondary={ReservationDetails?.pickUpLocationDetails?.code || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Today}
            primary={"Drop off Date and Time"}
            secondary={
              ReservationDetails?.dropOffDateAndTime.toString() || "N/A"
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.LocationOn}
            primary={"Drop off Location Name"}
            secondary={
              ReservationDetails?.dropOffLocationDetails?.code || "N/A"
            }
          />
        </div>
      </List>
      <List>
        <ListSubheader style={{ fontSize: "1.2rem" }}>
          User Details
        </ListSubheader>
        <div className="grid md:grid-cols-2 md:gap-2 lg:grid-cols-4 lg:gap-4 grid-cols-1 gap-0">
          <DetailsCard
            ImageIcon={AllIcons.AccountCircle}
            primary={"First Name "}
            secondary={UserDetails.firstName || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.AccountCircle}
            primary={"Last name "}
            secondary={UserDetails.lastName || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Email}
            primary={"Email "}
            secondary={UserDetails.email || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.LocalPhone}
            primary={"Mobile Number "}
            secondary={UserDetails.mobileNumber || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.FlagCircle}
            primary={"Country "}
            secondary={UserDetails.country || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Public}
            primary={"State "}
            secondary={UserDetails.state || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Public}
            primary={"City "}
            secondary={UserDetails.city || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Public}
            primary={"Zip "}
            secondary={UserDetails.zip || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Today}
            primary={"Date Of Birth "}
            secondary={
              UserDetails?.dateOfBirth
                ? new Date(
                    UserDetails?.dateOfBirth?.toString()
                  ).toLocaleDateString()
                : "N/A"
            }
          />
          <DetailsCard
            ImageIcon={AllIcons.Public}
            primary={"Birth Nation "}
            secondary={UserDetails.birthNation || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Public}
            primary={"Birth Place "}
            secondary={UserDetails.birthPlace || "N/A"}
          />
          <DetailsCard
            ImageIcon={AllIcons.Wc}
            primary={"Gender "}
            secondary={UserDetails.sex || "N/A"}
          />
        </div>
      </List>
    </div>
  );
}
