import InfiniteScroll from "react-infinite-scroll-component";
import { BookingVehicleInfoCard } from "../BookingVehicleInfoCard/BookingVehicleInfoCard";
import React, { useEffect, useState } from "react";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { ShowVehiclesProps } from "./ShowVehiclesProps";
import { Box, CircularProgress } from "@mui/material";
import { start } from "repl";
import AutoCard from "../../UI/Custom/AutoCard";

export function ShowVehicles({
  vehiclesFilter = {
    status: {},
    category: {},
    type: {},
    TotalSeats: 2,
    MacroGroup: {},
  },
  elementsToShowInOnePage = 3,
  allVehicles = [],
  onVehicleSelect = () => {},
}: ShowVehiclesProps): JSX.Element {
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
  const [unAvailableVehicles, setUnAvailableVehicles] = useState<Vehicle[]>([]);
  // const [onRequestVehicle, setOnRequestVehicles] = useState<Vehicle[]>([]);
  const [availableVehiclesPageNumber, setAvailableVehiclesPageNumber] =
    useState<number>(0);
  const [unAvailableVehiclesPageNumber, setUnAvailableVehiclesPageNumber] =
    useState<number>(0);
  // const [onRequestVehiclesPageNumber, setOnRequestVehiclesPageNumber] =
  //   useState<number>(0);
  const [showingVehicles, setShowingVehicles] = useState<Vehicle[]>([]);
  const [isLoadingMoreVehicles, setIsLoadingMoreVehicles] =
    useState<boolean>(false);
  const [haveMoreVehiclesToShow, setHaveMoreVehiclesToShow] =
    useState<boolean>(false);

  function addAvailableVehicles() {
    const startIndex = availableVehiclesPageNumber * elementsToShowInOnePage;
    const endIndex = startIndex + elementsToShowInOnePage;
    //const result: Vehicle[] = availableVehicles.slice(startIndex, endIndex);
    const result: Vehicle[] = availableVehicles.slice(startIndex, endIndex);
    if (result.length) {
      setShowingVehicles((e) => {
        return [...e, ...result];
      });
      setAvailableVehiclesPageNumber((prev) => prev + 1);
    }
    return result;
  }
  function addUnAvailableVehicles() {
    const startIndex = unAvailableVehiclesPageNumber * elementsToShowInOnePage;
    const endIndex = startIndex + elementsToShowInOnePage;
    const result: Vehicle[] = unAvailableVehicles.slice(startIndex, endIndex);
    if (result.length) {
      setShowingVehicles((e) => {
        return [...e, ...result];
      });
      setUnAvailableVehiclesPageNumber((prev) => prev + 1);
    }
    return result;
  }
  // function addOnRequestVehicles() {
  //   const startIndex = onRequestVehiclesPageNumber * elementsToShowInOnePage;
  //   const endIndex = startIndex + elementsToShowInOnePage;
  //   const result: Vehicle[] = onRequestVehicle.slice(startIndex, endIndex);
  //   if (result.length) {
  //     setShowingVehicles((e) => {
  //       return [...e, ...result];
  //     });
  //     setOnRequestVehiclesPageNumber((prev) => prev + 1);
  //   }
  //   return result;
  // }
  function loadMoreVehicles() {
    let result: Vehicle[] = [];
    setIsLoadingMoreVehicles(true);
    if (vehiclesFilter.status["available"]) {
      result = [...result, ...addAvailableVehicles()];
    }
    // if (
    //   vehiclesFilter.status["onrequest"] &&
    //   result.length < elementsToShowInOnePage
    // ) {
    //   result = [...result, ...addOnRequestVehicles()];
    // }
    if (
      vehiclesFilter.status["unavailable"] &&
      result.length < elementsToShowInOnePage
    ) {
      result = [...result, ...addUnAvailableVehicles()];
    }
    // console.log(result);

    setHaveMoreVehiclesToShow(true);
    setIsLoadingMoreVehicles(false);
  }
  function segregateData() {
    setAvailableVehicles(
      allVehicles.filter((e) => e.Status.toLowerCase() === "available") || []
    );
    // ).filter((e) => {
    //   // console.log(
    //   //   !!vehiclesFilter.type[
    //   //     e.Vehicle?.fuelType?.description?.toLowerCase() || ""
    //   //   ]
    //   // );

    //   if (Object.keys(vehiclesFilter.type).length > 0) {
    //     return !!vehiclesFilter.type[
    //       e.Vehicle?.fuelType?.description?.toLowerCase() || ""
    //     ];
    //   }
    //   return e;
    // })
    //);
    setUnAvailableVehicles(
      (
        allVehicles.filter((e) => e.Status.toLowerCase() === "unavailable") ||
        []
      ).filter((e) => {
        if (Object.keys(vehiclesFilter.type).length < 1) {
          return e;
        }
        return !!vehiclesFilter.type[
          e.Vehicle?.fuelType?.description?.toLowerCase() || ""
        ];
      })
    );
    // setOnRequestVehicles(
    //   (
    //     allVehicles.filter((e) => e.Status.toLowerCase() === "onrequest") || []
    //   ).filter((e) => {
    //     if (Object.keys(vehiclesFilter.type).length < 1) {
    //       return e;
    //     }
    //     return !!vehiclesFilter.type[
    //       e.Vehicle?.fuelType?.description?.toLowerCase() || ""
    //     ];
    //   })
    // );
  }
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [allVehicles]);

  function RefreshData() {
    setShowingVehicles([]);
    setAvailableVehiclesPageNumber(0);
    setUnAvailableVehiclesPageNumber(0);
    // setOnRequestVehiclesPageNumber(0);
    loadMoreVehicles();
  }

  useEffect(() => {
    RefreshData();
  }, [
    availableVehicles,
    unAvailableVehicles,
    // onRequestVehicle,
    vehiclesFilter,
  ]);

  useEffect(() => {
    RefreshData();
  }, []);
  return (
    <>
      {/*<p className={"text-black"}>{JSON.stringify(isLoadingMoreVehicles)}</p>*/}
      {/* <p className={"text-black"}>
        {JSON.stringify(haveMoreVehiclesToShow)}
        {showingVehicles.length}
      </p> */}
      {/* <InfiniteScroll
        style={{ height: "auto" }}
        dataLength={showingVehicles.length}
        next={() => {
          loadMoreVehicles();
        }}
        hasMore={haveMoreVehiclesToShow}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      > */}
      {!isLoadingMoreVehicles && (
        <div className="contenitore-offerte">
          {availableVehicles.length
            ? availableVehicles.map((el: Vehicle, index) => {
                return (
                  <div
                    className="risultato"
                    key={index}
                    // className="border-solid border-2 border-gray-300 rounded cursor-pointer hover:border-blue-500 hover:border-3"
                  >
                    {/* <AutoCard key={`ve_${index}`} data={el}></AutoCard>; */}
                    <BookingVehicleInfoCard
                      extras={
                        el.optionals
                          ? el.optionals?.map((e) => {
                              return {
                                iconUrl: e.Equipment.optionalImage || "#",
                                maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                                optionName: e.Equipment?.EquipType || "",
                                pricePerDayInUSD: (
                                  e.Charge.Amount || 0
                                ).toString(),
                                details: e.Equipment?.Description || "",
                              };
                            })
                          : []
                      }
                      status={el.Status}
                      modelYear=""
                      vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                      priceDetails={{
                        payNow: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                        payLater: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                      }}
                      // specifications={[
                      //   {
                      //     iconUrl: "#",
                      //     name: "fuel type",
                      //     value: el.Vehicle?.fuelType?.description || "N/A",
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "transmission type",
                      //     value:
                      //       el.Vehicle?.transmission?.description || "N/A",
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "seat number",
                      //     value: (el.Vehicle?.seats || "N/A").toString(),
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "seat number",
                      //     value: (el.Vehicle?.seats || "N/A").toString(),
                      //   },
                      // ]}
                      specifications={
                        //merge sorting done
                        el.Vehicle?.vehicleParameter
                          .sort((e1, e2) => {
                            return e1["position :"] - e2["position :"];
                          })
                          .map((e) => {
                            return {
                              iconUrl: e["fileUrl :"],
                              name: e["name :"],
                              value: e["name :"],
                            };
                          }) || []
                      }
                      topFeature={""}
                      vehicleImages={[
                        el.Vehicle?.vehicleGroupPic || "",
                        ...(el.Vehicle?.vehicelExtraImage || []).filter((e) => {
                          return !!e;
                        }),
                      ]}
                      vehicleName={el.Vehicle?.VehMakeModel?.Name || ""}
                      payLater={"Pay Later"}
                      disclaimer={
                        "Le opzioni di questo veicolo da consegnare possono variare"
                      }
                      payNow={"Pay Now"}
                      selectExtra={"Choose Insurance"}
                      closeExtra={"Chiudi extra"}
                      viewAllSpecifications={"Visualizza tutte le specifiche"}
                      moveToPayment={(e) => {
                        //  console.log(e, el);
                        onVehicleSelect({
                          vehicleDetails: el,
                          paymentDetails: e,
                        });
                      }}
                    />
                  </div>
                );
              })
            : "No Data To Show"}
        </div>
      )}
      {isLoadingMoreVehicles && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* </InfiniteScroll> */}
    </>
  );
}
