import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { IndividualUserForms } from "../../components/UI/AllWebCheckInForms/IndividualUserForms";
import NotAuthorisedPage from "../notAuthorisedPage";

export function CustomerDetails() {
  const [showReserve, setShowReserve] = useState<boolean>();
  console.log(showReserve);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
      console.log(e.reservedArea);
      // setShowReserve(true);
    });
  }, [showReserve]);

  return (
    <>
      {showReserve ? (
        <div>
          {" "}
          <IndividualUserForms />
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </>
  );
}
