import {
  createSearchParams,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";

import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingForm from "../../components/Layouts/BookingForm/BookingForm";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BookingApi from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import { addMinutes } from "date-fns";
import { TimeInMinutes } from "../../helpers/timerConfig";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { BookingDetails } from "../../@types/Booking/bookingDetails";
import AutoCard from "../../components/UI/Custom/AutoCard";

function HomePage(): JSX.Element {
  const homePageConfig = PageConfigGetters.getHomePageConfig();
  const BookingFormConfig = PageConfigGetters.getBookingFormConfig();
  const dispatch = useDispatch();
  const routerNavigation: NavigateFunction = useNavigate();
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);
  const [showBooking, setShowBooking] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      //setShowBooking(e.booking);
      setShowBooking(true);
    });
  }, [showBooking]);

  return (
    <div className="min-h-screen flex flex-col">
      <section className="h-full  box-border flex flex-col md:px-12 px-4 ">
        {/* <header className="font-bold box-border h-56 flex flex-row items-end mb-10 ">
          <div
            className={"w-full" + " " + homePageConfig.descriptionTextClasses}
          >
            <h2 className="text-5xl">{homePageConfig.title}</h2>
            <h4 className="md:w-1/5 w-full  py-4">{homePageConfig.subTitle}</h4>
          </div>
        </header> */}
        <div className="main wrapper clearfix">
          <div id="tabRic">
            <ul id="tabs">
              <li>
                <a href="#" id="tab1">
                  Noleggia
                </a>
              </li>
              <li>
                <a href="https://autoone.it/vendita-auto-usate" id="tab2">
                  Acquista
                </a>
              </li>
            </ul>

            <div id="content">
              <div id="tab1">
                {/* <img style={{ height: "100px" }} src="assets/img/loading.webp" /> */}

                {showBooking ? (
                  <div className="">
                    {BookingFormConfig.fields && (
                      <BookingForm
                        className=""
                        options={BookingFormConfig.fields || []}
                        onSubmit={(e) => {
                          dispatch(
                            BOOKING_ACTIONS.setBookingInformation({
                              ...e,
                            })
                          );

                          const searchParams = createSearchParams({
                            dropOffDateAndTime: e.dropOffDateAndTime,
                            pickUpDateAndTime: e.pickUpDateAndTime,
                            pickUpLocation: e.pickUpLocationDetails.code,
                            dropOffLocation: e.dropOffLocationDetails.code,
                            reservationSource: e.reservationSource.code,
                            macroGroupName: e.macroGroupName,
                            discountValueWithoutVat: e.discountValueWithoutVat,
                            agreementCoupon: e.agreementCoupon,
                            showPics: "true",
                            showOptionalImage: "true",
                            showVehicleParameter: "true",
                            showVehicleExtraImage: "true",
                          }).toString();

                          // const searchParams = {
                          //   dropOffDateAndTime: e.dropOffDateAndTime,
                          //   pickUpDateAndTime: e.pickUpDateAndTime,
                          //   pickUpLocation: e.pickUpLocationDetails.code,
                          //   dropOffLocation: e.dropOffLocationDetails.code,
                          //   reservationSource: e.reservationSource.code,
                          //   macroGroupName: e.macroGroupName,
                          //   discountValueWithoutVat: e.discountValueWithoutVat,
                          //   agreementCoupon: e.agreementCoupon,
                          //   showPics: "true",
                          //   showOptionalImage: "true",
                          //   showVehicleParameter: "true",
                          //   showVehicleExtraImage: "true",
                          // };

                          routerNavigation("/choose/vehicle?" + searchParams);
                          // console.log(searchParams);
                          // const currentBookingDetails: BookingDetails = {
                          //   pickUpDateAndTime: new Date(
                          //     parseInt(searchParams.pickUpDateAndTime)
                          //   ),
                          //   dropOffDateAndTime: new Date(
                          //     parseInt(searchParams.dropOffDateAndTime)
                          //   ),
                          //   dropOffLocationDetails: {
                          //     name: "",
                          //     code: searchParams.dropOffLocation,
                          //   },
                          //   pickUpLocationDetails: {
                          //     name: "",
                          //     code: searchParams.pickUpLocation,
                          //   },
                          //   reservationSource: {
                          //     name: "",
                          //     code: searchParams.reservationSource || "",
                          //   },
                          //   macroGroupName: searchParams.macroGroupName || "",
                          //   showPics: searchParams.showPics == "true",
                          //   showOptionalImage:
                          //     searchParams.showOptionalImage == "true",
                          //   showVehicleParameter:
                          //     searchParams.showVehicleParameter == "true",
                          //   showVehicleExtraImage:
                          //     searchParams.showVehicleExtraImage == "true",
                          //   agreementCoupon: searchParams.agreementCoupon || "",
                          //   discountValueWithoutVat:
                          //     searchParams.discountValueWithoutVat || "",
                          // };

                          // BookingApi.getReservationVehicles(
                          //   currentBookingDetails
                          // )
                          //   .then(async (e) => {
                          //     dispatch(
                          //       BOOKING_ACTIONS.setSessionExpiryTime(
                          //         addMinutes(
                          //           new Date(),
                          //           TimeInMinutes
                          //         ).getTime()
                          //       )
                          //     );
                          //     setAllVehicles([
                          //       ...(e.data?.data?.Vehicles || []),
                          //     ]);
                          //     console.log(allVehicles);
                          //   })
                          //   .catch(() => {
                          //     // navigation("/");
                          //   })
                          //   .finally(() => {
                          //     //setIsLoading(false);
                          //   });
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <NotAuthorisedPage />
                )}
              </div>
              <div id="tab2">
                {/* <img style={{ height: "100px" }} src="assets/img/loading.webp" /> */}
              </div>
            </div>
          </div>

          <aside>
            <h3>MIGLIOR OFFERTA</h3>
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>500e</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
              <div className="prezzo">
                <span>20</span>€ / giorno
              </div>
            </div>
            <img src="assets/img/500e.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </aside>
        </div>
        <div className="main wrapper clearfix">
          {allVehicles.length
            ? allVehicles.map((el: Vehicle, index) => {
                console.log(el);
                return <AutoCard key={`ve_${index}`} data={el}></AutoCard>;
              })
            : "No Data To Show"}

          {/* <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Q2</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/q2.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>500L</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/500l.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Panda</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/panda.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div> */}
        </div>
        {/* <div className="main wrapper clearfix">
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Corsa</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/corsa.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>500L</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/500l.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Q2</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/q2.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
        </div>
        <div className="main wrapper clearfix">
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Q2</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/q2.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>500L</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/500l.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
          <div className="offerta">
            <div className="left">
              <span className="gruppo">MINI/GRUPPO B2</span>
              <h3>Panda</h3>
              <span className="vettSimile">
                o vettura simile dello stesso gruppo<i className="ttSimile"></i>
              </span>
            </div>
            <div className="right">
              <span>20</span>€ / giorno
            </div>
            <img src="assets/img/panda.png" />
            <div className="opt">
              <i className="alimentazione"></i>Benzina
            </div>
            <div className="opt">
              <i className="motore"> </i>3 Porte
            </div>
            <div className="opt">
              <i className="cambio"></i>Manuale
            </div>
            <div className="opt">
              <i className="trazione"></i>1 Bagaglio
            </div>
            <div className="azione">
              <a href="#">Prenota</a>
            </div>
          </div>
        </div> */}

        <div id="contSlider">
          <div className="slider">
            <div>
              <img src="assets/img/slide_bottom.png" />
            </div>
            <div>
              <img src="assets/img/slide_bottom.png" />
            </div>
          </div>
        </div>
        {/* <p
          className={
            "text-sm font-bold box-border pt-4" +
            " " +
            homePageConfig.descriptionTextClasses
          }
        >
          {homePageConfig.disclaimer}
        </p> */}
      </section>
      <section>
        <div id="ups">
          <h2>I NOSTRI ACCESSORI NOLEGGIO</h2>
          <ul>
            <li>
              <img src="assets/img/copertura_danni.png" />
              Copertura 2 giorni
            </li>
            <li>
              <img src="assets/img/cross.png" />
              Fuori confine
            </li>
            <li>
              <img src="assets/img/full.png" />
              Pieno per vuoto
            </li>
            <li>
              <img src="assets/img/nav.png" />
              Navigatore
            </li>
            <li>
              <img src="assets/img/road.png" />
              Soccorso Stradale
            </li>
            <li>
              <img src="assets/img/vetro.png" />
              Copertura vetri
            </li>
            <li>
              <img src="assets/img/ruote.png" />
              Gomme invernali
            </li>
            <li>
              <img src="assets/img/baby.png" />
              Isofix e seggiolino
            </li>
          </ul>
        </div>
        <div className="main wrapper clearfix scopri">
          <a href="#">Scopri di più</a>
        </div>
      </section>
      <div className="footer-container">
        <footer className="wrapper">
          <div className="firstFoo">
            <img src="assets/img/logo.png" style={{ height: "50px" }} />
          </div>
          <div>
            <ul>
              <li>
                <a href="#">Cookies Policy</a>
              </li>
              <li>
                <a href="#">Informativa Privacy</a>
              </li>
              <li>
                <a href="#">Termini & Condizioni</a>
              </li>
              <li>
                <a href="#">Copyright ©2023</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href="#">Chi siamo</a>
              </li>
              <li>
                <a href="#">Le nostre sedi</a>
              </li>
              <li>
                <a href="#">Asisstenza</a>
              </li>
              <li>
                <a href="#">Contattaci</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href="#">Cookies Policy</a>
              </li>
              <li>
                <a href="#">Informativa Privacy</a>
              </li>
              <li>
                <a href="#">Termini & Condizioni</a>
              </li>
              <li>
                <a href="#">Copyright ©2023</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href="#">Chi siamo</a>
              </li>
              <li>
                <a href="#">Le nostre sedi</a>
              </li>
              <li>
                <a href="#">Asisstenza</a>
              </li>
              <li>
                <a href="#">Contattaci</a>
              </li>
            </ul>
          </div>
          <div>
            <img src="assets/img/numero_verde.png" />
          </div>
        </footer>
      </div>
    </div>
  );
}
export default HomePage;
