import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppInputSwitchProps } from "../components/Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { BookingApi } from "../API/Apis/BookingApi";
import { SingleFormFieldType } from "../components/UI/AppForm/AppForm.props";

export const useWebCheckInForm = (
  initialFormFields: Record<
    string,
    AppInputSwitchProps | SingleFormFieldType
  > = {},
  initialValues: Record<string, any> = {},
  onValueChange: (e: Record<string, any>) => void = () => {}
): [
  Record<any, any>,
  Dispatch<SetStateAction<Record<any, any>>>,
  Record<any, any>,
  Dispatch<SetStateAction<Record<any, any>>>
] => {
  const [values, setValues] = useState({ ...initialValues });
  const [formFields, setFormFields] = useState({ ...initialFormFields });
  function setCountries() {
    const tempFields = { ...formFields };
    const tempValue = { ...values };
    let isCountryItaly = false;
    if (tempFields.country) {
      tempFields.country.type = "text";
      tempFields.country.variant = "standard";
      tempFields.country.readOnly = true;
      isCountryItaly = tempValue[tempFields?.country?.key]
        ?.toLowerCase()
        .includes("ital");
    }
    if (tempFields.state) {
      tempFields.state.type = "text";
      tempFields.state.variant = "standard";
      tempFields.state.readOnly = true;
      if (
        isCountryItaly &&
        tempValue[tempFields.state.key]?.toLowerCase().includes("ee")
      ) {
        tempValue[tempFields.state.key] = "";
      }
    }
    if (tempFields.city) {
      tempFields.city.type = "text";
      tempFields.city.variant = "standard";
      tempFields.city.readOnly = true;
      if (
        isCountryItaly &&
        tempValue[tempFields.city.key]?.toLowerCase().includes("ee")
      ) {
        tempValue[tempFields.city.key] = "";
      }
    }
    if (tempFields.zip) {
      tempFields.zip.type = "text";
      tempFields.zip.variant = "standard";
      tempFields.zip.readOnly = true;
    }
    if (tempFields.birthNation) {
      tempFields.birthNation.type = "text";
      tempFields.birthNation.variant = "standard";
      tempFields.birthNation.readOnly = true;
    }

    setFormFields({
      ...tempFields,
    });
    setValues({
      ...tempValue,
    });
    BookingApi.getAllCountries().then(async (data) => {
      const allCountries = data.data || [];
      if (tempFields.country) {
        tempFields.country.readOnly = false;
        tempFields.country.type = "select";
        tempFields.country.variant = "with-search";
        tempFields.country.options = allCountries;
        tempFields.country.readOnly = false;
      }
      if (tempFields.birthNation) {
        tempFields.birthNation.options = allCountries;
        tempFields.birthNation.readOnly = false;
        tempFields.birthNation.type = "select";
        tempFields.birthNation.variant = "with-search";
        tempFields.birthNation.readOnly = false;
      }
      setFormFields({
        ...tempFields,
      });
      setCity();
    });
  }
  async function setStateAndZip(cityName: string = values.city || "") {
    if (!cityName || cityName.toLowerCase() == "ee") {
      return;
    }
    const tempFields = { ...formFields };
    if (tempFields.zip) {
      tempFields.zip.type = "select";
      tempFields.zip.variant = "with-search";
      tempFields.zip.loading = true;
      tempFields.zip.loadingText = "Finding zip Codes";
    }
    if (tempFields.state) {
      tempFields.state.type = "select";
      tempFields.state.variant = "with-search";
      tempFields.state.loading = true;
      tempFields.state.loadingText = "Finding States";
    }
    if (cityName?.toLowerCase().includes("ee")) {
      if (tempFields.city) {
        tempFields.city.type = "select";
        tempFields.city.variant = "with-search";
        tempFields.city.loading = true;
        tempFields.city.loadingText = "Finding cities";
      }
      setFormFields({ ...tempFields });
      return;
    }
    BookingApi.getCity(cityName).then((data) => {
      const stateOptions = data.data.state.filter((e) => {
        return !e.toLowerCase().includes("xxx");
      });
      const zipOptions = data.data.zip.filter((e) => {
        return !e.toLowerCase().includes("xxx");
      });
      if (!stateOptions.length && !zipOptions.length) {
        return;
      }
      if (tempFields.zip) {
        tempFields.zip.options = zipOptions;
        tempFields.zip.readOnly = false;
        tempFields.zip.type = "select";
        tempFields.zip.variant = "with-search";
      }
      if (tempFields.state) {
        tempFields.state.options = stateOptions;
        tempFields.state.readOnly = false;
        tempFields.state.type = "select";
        tempFields.state.variant = "with-search";
      }
      setFormFields({ ...tempFields });
    });
    setFormFields({ ...tempFields });
  }

  function setCity(countryName: string = values.country) {
    if (!countryName) {
      return;
    }
    const tempFields = { ...formFields };
    if (tempFields.state) {
      tempFields.state.loading = true;
      tempFields.state.loadingText = "Finding States";
    }
    if (tempFields.city) {
      tempFields.city.loading = true;
      tempFields.city.loadingText = "Finding cities";
    }
    if (tempFields.zip) {
      tempFields.zip.loading = true;
      tempFields.zip.loadingText = "Finding Zip Codes";
    }

    BookingApi.getStates(countryName).then((data) => {
      if (tempFields.city) {
        const tempValues = { ...values };
        tempFields.city.options = data.data;
        if (tempFields.city) {
          tempFields.city.loading = false;
          tempFields.city.type = "select";
          tempFields.city.variant = "with-search";
          tempFields.city.readOnly = false;
          // tempValues[tempFields.city.key] = tempFields.city?.options[0] || "";
        }
        if (tempFields.state) {
          tempFields.state.loading = false;
          tempFields.state.type = "select";
          tempFields.state.variant = "with-search";
          tempFields.state.readOnly = false;
        }
        setValues({ ...tempValues });
        setFormFields({ ...tempFields });
      }
    });

    setFormFields({ ...tempFields });
  }

  // country change effect
  useEffect(() => {
    const isCountryItaly = !!values.country?.toLowerCase().includes("ital");
    const tempFormFields = { ...formFields };
    const tempValues = { ...values };
    if (tempFormFields.state) {
      tempFormFields.state.readOnly = true;
      tempFormFields.state.type = "text";
      tempFormFields.state.variant = "standard";
      tempValues[tempFormFields.state.key] = "EE";
      tempFormFields.state.options = [];
    }
    if (tempFormFields.city) {
      tempFormFields.city.readOnly = true;
      tempFormFields.city.type = "text";
      tempFormFields.city.options = [];
      tempFormFields.city.variant = "standard";
      tempValues[tempFormFields.city.key] = "EE";
    }
    if (tempFormFields.zip) {
      tempFormFields.zip.readOnly = false;
      tempFormFields.zip.options = [];
      tempFormFields.zip.type = "text";
      tempFormFields.zip.variant = "standard";
      tempValues[tempFormFields.zip.key] = "";
    }
    if (tempFormFields.ssn) {
      tempFormFields.ssn.readOnly = true;
      tempFormFields.ssn.type = "text";
      tempFormFields.ssn.variant = "standard";
      tempValues[tempFormFields.ssn.key] = "9999999999999999";
    }
    if (tempFormFields.birthNation) {
      tempFormFields.birthNation.readOnly = false;
    }
    if (tempFormFields.birthProv) {
      tempFormFields.birthProv.readOnly = false;
    }
    if (tempFormFields.birthDate) {
      tempFormFields.birthDate.readOnly = false;
    }
    if (tempFormFields.birthPlace) {
      tempFormFields.birthPlace.readOnly = false;
    }
    if (isCountryItaly) {
      if (tempFormFields.birthNation) {
        tempFormFields.birthNation.readOnly = true;
      }
      if (tempFormFields.birthProv) {
        tempFormFields.birthProv.readOnly = true;
      }
      if (tempFormFields.birthDate) {
        tempFormFields.birthDate.readOnly = true;
      }
      if (tempFormFields.birthPlace) {
        tempFormFields.birthPlace.readOnly = true;
      }
      if (tempFormFields.state) {
        tempFormFields.state.readOnly = false;
        tempFormFields.state.type = "select";
        tempFormFields.state.variant = "with-search";
        tempValues[tempFormFields.state.key] = "";
      }
      if (tempFormFields.city) {
        tempFormFields.city.readOnly = false;
        tempFormFields.city.type = "select";
        tempFormFields.city.variant = "with-search";
        tempValues[tempFormFields.city.key] = "";
      }
      if (tempFormFields.zip) {
        tempFormFields.zip.readOnly = false;
        tempFormFields.zip.type = "select";
        tempFormFields.zip.variant = "with-search";
      }
      if (tempFormFields.ssn) {
        tempFormFields.ssn.readOnly = false;
        tempFormFields.ssn.type = "text";
        tempFormFields.ssn.variant = "standard";
        tempValues[tempFormFields.ssn.key] = "";
      }
      setCity(values.country || "");
    }
    setFormFields({ ...tempFormFields });
    setValues({ ...tempValues });
  }, [values?.country]);
  useEffect(() => {
    const tempFields = { ...formFields };
    const tempValues = { ...values };
    if (values[formFields?.country.key]?.toLowerCase().includes("ital")) {
      if (tempFields.birthProv) {
        tempValues[tempFields.birthProv.key] = "";
      }
      if (tempFields.birthNation) {
        tempValues[tempFields.birthNation.key] = "";
      }
      if (tempFields.birthPlace) {
        tempValues[tempFields.birthPlace.key] = "";
      }
      if (tempFields.gender) {
        tempValues[tempFields.gender.key] = "";
      }
      if (tempFields.birthDate) {
        tempValues[tempFields.birthDate.key] = "";
      }
      setValues({ ...tempValues });
      BookingApi.getItalianCustomerDetailsBySSN(
        values[formFields?.ssn?.key] || ""
      )
        .then((e) => {
          if (e.data.ninedigit) {
            return;
          }
          if (tempFields.birthProv && e.data.birthProvince) {
            tempValues[tempFields.birthProv.key] = e.data.birthProvince;
          }
          if (tempFields.birthNation && e.data.birthNation) {
            tempValues[tempFields.birthNation.key] = e.data.birthNation;
          }
          if (tempFields.birthPlace && e.data.birthPlace) {
            tempValues[tempFields.birthPlace.key] = e.data.birthPlace;
          }
          if (tempFields.gender && e.data.gender) {
            tempValues[tempFields.gender.key] =
              e.data.gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
          }
          if (tempFields.birthDate) {
            tempValues[tempFields.birthDate.key] = e.data.dataofbirth;
          }
          setValues({ ...tempValues });
        })
        .catch();
    }
  }, [values[formFields?.ssn?.key]]);
  useEffect(() => {
    setCountries();
  }, []);
  useEffect(() => {
    setStateAndZip().then();
  }, [values?.city]);
  useEffect(() => {
    onValueChange(values);
  }, [values]);
  return [values, setValues, formFields, setFormFields];
};
