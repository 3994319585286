import { BookingStateRedux } from "./states";
import { PayloadAction } from "@reduxjs/toolkit";

export default {
  // application settings
  setCurrency(state, value) {
    state.currency = value;
  },
  setIsActive(state, value) {
    state.isActive = value;
  },
  setIsEmbedded(state, { payload: value }) {
    state.isEmbedded = value;
    // console.log(value);
  },
  setIsLogged(state, value) {
    state.isLoggedIn = !!value;
  },
  setRentalTerms(state, { payload: value }) {
    state.rentalTerms = value;
  },

  // APIs
  setBookingInformation(
    state,
    { payload: value }: PayloadAction<BookingStateRedux["bookingInformation"]>
  ) {
    state.bookingInformation = {
      ...value,
      dropOffDateAndTime: new Date(value.dropOffDateAndTime || Date.now()),
      pickUpDateAndTime: new Date(value.pickUpDateAndTime || Date.now()),
    } as BookingStateRedux["bookingInformation"];
  },
  setSessionExpiryTime(
    state: BookingStateRedux,
    { payload: value }: PayloadAction<BookingStateRedux["sessionExpiryTime"]>
  ) {
    state.sessionExpiryTime = value;
  },
  setSelectedVehicleDetails(
    state: BookingStateRedux,
    {
      payload: value,
    }: PayloadAction<BookingStateRedux["selectedVehicleDetails"]>
  ) {
    state.selectedVehicleDetails = {
      vehicleDetails: {
        optionals: [],
        Status: "Unavailable",
        TotalCharge: null,
        Vehicle: undefined,
      },

      paymentDetails: {
        // ...value.paymentDetails,
        selectedInsurance: value.paymentDetails?.selectedInsurance || {
          price: 0,
          details: "",
          name: "insurance",
        },
        addedExtraOptions: value.paymentDetails?.addedExtraOptions || {},
        type: value.paymentDetails?.type || "PAYNOW",
        price: ((value.paymentDetails?.price || 0) * 100) / 100,
      },
      ...value,
    };
  },
  setUserDetails(
    state: BookingStateRedux,
    { payload: value }: PayloadAction<BookingStateRedux["userDetails"]>
  ) {
    state.userDetails = value;
  },
  setAppSetting(
    state: BookingStateRedux,
    { payload: value }: PayloadAction<BookingStateRedux["appSetting"]>
  ) {
    state.appSetting = value;
  },
};
